import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

// SLICE IMPORTS
import authSlice from '../slices/authSlice';
import systemSlice from '../slices/systemSlice';
import collaborationSlice from '../slices/collaborationSlice';
import clientSlice from '../slices/clientSlice';
import auditLogsSlice from '../slices/auditLogsSlice';
import messageReducer from '../slices/message';
import brandingSlice from '../slices/brandingSlice';
import requestSlice from '../slices/requestSlice';
import notificationSlice from '../slices/notificationSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'system',
    'collaboration',
    'client',
    'auditLogs',
    'branding',
    'request',
    'notification'
  ]
};

const rootReducer = combineReducers({
  auth: authSlice,
  system: systemSlice,
  collaboration: collaborationSlice,
  client: clientSlice,
  auditLogs: auditLogsSlice,
  message: messageReducer,
  branding: brandingSlice,
  request: requestSlice,
  notification: notificationSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
