import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import config from '../../../constants/config';
import Modal from '../../../components/Modal';
import BulkImportClient from './BulkImportClient';
import AssociateCompany from './AssociateCompany';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import { AddIndividualClientValidationSchema } from '../../Settings/validation';

// API
import {
  addBulkClient,
  addIndividualClient,
  getCompleteCompaniesList,
  resetError
} from '../../../slices/clientSlice';
import { allCollaborationUsersList } from '../../../slices/collaborationSlice';

/* ============================== ADD CLIENT MODAL ============================== */
const AddClientModal = ({
  handleCloseModel,
  setClientAdded,
  setOpenIndividualModal,
  setOpenCompanyModal,
  formSidebar,
  typeModal,
  manageClientModelId
}) => {
  const [formError, setFormError] = useState('');
  const [bulkImport, setBulkImport] = useState(0);
  const [dropdownErr, setDropdownErr] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkImportError, setBulkImportError] = useState('');
  const [type, setType] = useState({});
  const [companies, setCompanies] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [dropdownValidation, setDropdownValidation] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [userType, setUserType] = useState('Individual');
  const [recordsArray, setRecordsArray] = useState([]);
  const { status, message } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const selectUserValidations = () => {
    if (Object.keys(type).length === 0) {
      setDropdownErr('Please select a user');
    } else {
      setDropdownErr('');
    }
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: ''
    },

    validationSchema: AddIndividualClientValidationSchema,
    validate: () => selectUserValidations(),
    onSubmit: (values) => {
      const reqData = {
        ...values,
        professional_user_id: manageClientModelId
          ? manageClientModelId.toString()
          : type.id.toString(),
        is_associated_to_company: selectedCompanyName > 0 ? true : false,
        company_id: selectedCompanyName > 0 ? selectedCompanyName.toString() : ''
      };
      dispatch(addIndividualClient(reqData)).then(() => setShowStatus(true));
    }
  });
  const checkImportType = (params) => {
    setBulkImport(params);
    setFormError('');
  };
  useEffect(() => {
    if (showStatus) {
      if (status === config.STATUS.SUCCESS) {
        handleCloseModel();
        setFormError('');
        setDropdownErr('');
        toast.success(TOAST_MESSAGE.USER_ADDED_SUCCESS);
        setClientAdded && setClientAdded(true);
      } else {
        status === config.STATUS.ERROR && setFormError(message);
      }
    }
    return () => {
      setShowStatus(false);
    };
  }, [showStatus]);

  useEffect(() => {
    if (typeModal === 'TeamCollaboration') {
      getAllCompanyList({
        page: false,
        limit: false,
        isPaginate: false,
        user_id: manageClientModelId
      });
    }
    getAllCollaborationUsersList({
      page: false,
      limit: false,
      include_logged_user: true
    });
  }, []);

  const getAllCollaborationUsersList = async (params) => {
    const response = await dispatch(allCollaborationUsersList(params)).unwrap();
    const profileSetUsers = response?.data?.records.filter((items) => items?.is_profile_set);
    setRecordsArray(profileSetUsers);
  };
  const getAllCompanyList = async (params) => {
    try {
      const response = await dispatch(getCompleteCompaniesList(params)).unwrap();
      setCompanies(response?.data?.records);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };
  const handleBulkImportSubmit = () => {
    if (Object.keys(type).length === 0) {
      setDropdownErr('Please select a user');
    } else {
      setDropdownErr('');
      const formData = new FormData();
      formData.append('csv_data', selectedFile);
      formData.append('professional_user_id', JSON.stringify(type.id));

      dispatch(addBulkClient(formData)).then(() => setShowStatus(true));
    }
    if (manageClientModelId) {
      setDropdownErr('');
      const formData = new FormData();
      formData.append('csv_data', selectedFile);
      formData.append('professional_user_id', JSON.stringify(manageClientModelId));
      dispatch(addBulkClient(formData)).then(() => setShowStatus(true));
    }
  };
  useEffect(() => {
    if (formError !== '') {
      setFormError('');
      dispatch(resetError());
    }
  }, [values]);

  const checkType = (event) => {
    setIsChecked(false);
    getAllCompanyList({
      page: false,
      limit: false,
      isPaginate: false,
      user_id: event?.id
    });
    setType(event);
    setDropdownErr('');
  };

  const handleUserType = (event) => {
    setUserType(event);
    if (event === 'Individual') {
      setOpenIndividualModal(true);
      setOpenCompanyModal(false);
    }
    if (event === 'Company') {
      setOpenIndividualModal(false);
      setOpenCompanyModal(true);
    }
  };
  const userTypeArray = ['Individual', 'Company'];
  return (
    <Modal
      title={formSidebar ? 'Add new user' : 'Add Individual'}
      show={true}
      className="cs-md-modal cs-new-user-modal cs-common-modal-length"
      body={
        <React.Fragment>
          {formSidebar && (
            <Row>
              <Col lg={12} sm={12}>
                <Form.Group>
                  <Form.Label className="cs-neutral-100">Select user type</Form.Label>

                  <Dropdown className="cs-form-dropdown">
                    <Dropdown.Toggle className="cs-regular-sub-heading-s cs-neutral-70">
                      {userType.length === 0 ? 'Select User ' : userType}

                      <span className="cs-neutral-90">
                        <CapsyncIcon title="chevron-down-outlined" size="18" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {userTypeArray.map((record) => (
                        <Dropdown.Item
                          key={record.id}
                          onClick={() => {
                            handleUserType(record);
                          }}
                          className={
                            record === userType
                              ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s cs-neutral-90'
                              : 'cs-regular-sub-heading-s cs-neutral-90 cs-content-title '
                          }>
                          {record}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={12} sm={12}>
              {!manageClientModelId && (
                <Form.Group>
                  <Form.Label className="cs-neutral-100">Select user</Form.Label>

                  <Dropdown className="cs-form-dropdown">
                    <Dropdown.Toggle className="cs-regular-sub-heading-s cs-neutral-70">
                      {Object.keys(type).length === 0 ? 'Select user ' : type.display_name}

                      <span className="cs-neutral-90">
                        <CapsyncIcon title="chevron-down-outlined" size="18" />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="cs-scroller">
                        {recordsArray.map((record) => (
                          <Dropdown.Item
                            key={record.id}
                            onClick={() => checkType(record)}
                            className={
                              record.id === type.id
                                ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s cs-neutral-90'
                                : 'cs-regular-sub-heading-s cs-neutral-90 cs-content-title '
                            }>
                            {record.display_name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                    <span className="form-error-msg cs-light-body-text-s cs-danger">
                      {dropdownErr}
                    </span>
                  </Dropdown>
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Group className="cs-form-group">
                <div className="cs-radio-group">
                  <div
                    className="cs-radio-btn"
                    onClick={() => {
                      checkImportType(0);
                    }}>
                    <input
                      className="radio-button cs-cursor-pointer"
                      type="radio"
                      label="1"
                      name="import"
                      checked={bulkImport === 0}
                    />
                    <span
                      className="cs-regular-h5 cs-neutral-90 cs-cursor-pointer"
                      onClick={() => {
                        checkImportType(0);
                      }}>
                      Single entry
                    </span>
                  </div>
                  <div
                    className="cs-radio-btn"
                    onClick={() => {
                      checkImportType(1);
                    }}>
                    <input
                      className="radio-button cs-cursor-pointer"
                      type="radio"
                      label="2"
                      name="import"
                      checked={bulkImport === 1}
                    />
                    <span
                      className="cs-regular-h5 cs-neutral-90 cs-cursor-pointer"
                      onClick={() => {
                        checkImportType(1);
                      }}>
                      Bulk import
                    </span>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          {bulkImport === 0 ? (
            <React.Fragment>
              <Form onSubmit={handleSubmit} className="cs-complete-profile">
                <Row>
                  <Col lg={6}>
                    <InputBox
                      label="First name"
                      name="first_name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputBox
                      label="Last name"
                      name="last_name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="last-form-field">
                      <InputBox
                        label="Email"
                        name="email"
                        type="email"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start">
                    <span className="cs-light-body-text-m cs-danger">{formError}</span>
                  </Col>
                </Row>
              </Form>
              <AssociateCompany
                setSelectedCompanyName={setSelectedCompanyName}
                setDropdownValidation={setDropdownValidation}
                dropdownValidation={dropdownValidation}
                setIsChecked={setIsChecked}
                type={type}
                typeModal={typeModal}
                companies={companies}
                isChecked={isChecked}
              />
            </React.Fragment>
          ) : (
            <BulkImportClient
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              bulkImportError={bulkImportError}
              setBulkImportError={setBulkImportError}
              setFormError={setFormError}
              formError={formError}
            />
          )}
        </React.Fragment>
      }
      saveButtonLabel={'Send Invitation'}
      cancelButtonLabel={'Cancel'}
      handleOnSave={bulkImport === 0 ? handleSubmit : handleBulkImportSubmit}
      handleOnCancel={handleCloseModel}
      isCloseButton={false}
    />
  );
};

// PROPS TYPE
AddClientModal.propTypes = {
  handleCloseModel: PropTypes.func,
  setClientAdded: PropTypes.func,
  setOpenIndividualModal: PropTypes.func,
  manageClientModelId: PropTypes.any,
  typeModal: PropTypes.string,
  setOpenCompanyModal: PropTypes.func,
  formSidebar: PropTypes.bool
};

export default AddClientModal;
