import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown, NavDropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { useOutletContext } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';

// COMPONENT IMPORTS
import { roles } from '../../utils/common';
import config from '../../constants/config';
import { socket } from '../../config/Socket';
import Searchbar from '../../components/Searchbar';
import CapsyncIcon from '../../components/CapsyncIcon';
import CustomTable from '../../components/CustomTable';
import DeleteModal from '../../components/DeleteModal';
import { TOAST_MESSAGE } from '../../constants/message';
import AddClientModal from './components/AddClientModal';
import CheckBox from '../../components/FormFields/CheckBox';
import EditCompanyModal from './components/EditCompanyModal';
import CapsyncToolTip from '../../components/CapsyncToolTip';
import ChooseUserModal from '../Settings/components/ChooseUserModal';
import ManageIndividualClientModel from './components/ManageIndividualClientModal';
import SkeletonIndCompany from '../components/skeleton/SkeletonIndCompany';
import ViewCompaniesModal from '../Settings/components/ViewCompaniesModal';

// HOOKS IMPORTS
import useDebounce from '../../hooks/useDebounce';

// API
import {
  addRecentlyViewedIndividual,
  allClientsList,
  deleteClient,
  resetError
} from '../../slices/clientSlice';
import { resendEmailVerification } from '../../slices/authSlice';

/* ============================== INDIVIDUAL CLIENT ============================== */
const IndividualClient = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { clientsRecord } = useSelector((state) => state.client);

  const token = secureLocalStorage.getItem('token');
  const redirectDomain = user?.firm?.firm_brand_details?.domain;
  const firmID = user?.firm_id.toString();
  const role = user && user.user_role.role;
  const userRole = user?.role_id;
  const professionalUserId = user?.id;

  const [isModelOpen, setIsModelOpen] = useState({
    addIndividual: false,
    // viewDetails: false,
    manageClient: false,
    addClient: false,
    removeUser: false,
    moveUser: false,
    addCompany: false,
    viewCompanies: false
  });
  const [manageClientModelId, setManageClientModelId] = useState(-1);
  const [clientAdded, setClientAdded] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersDetails, setSelectedUsersDetails] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [isIndividualClient, setIsIndividualClient] = useState('');
  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [columnDetails, setColumnDetails] = useState({});
  const [checkExport, setCheckExport] = useState(false);
  const [viewCompaniesData, setViewCompaniesData] = useState();

  useEffect(() => {
    onPaginationValueChange({
      page: 1,
      limit: 10,
      isPaginate: true
    });
    return () => {
      setClientAdded(false);
    };
  }, [clientAdded]);

  // Sockets

  useEffect(() => {
    socket.on('sync_client_list', () => {
      onPaginationValueChange({
        page: 1,
        limit: 10,
        isPaginate: true
      });
    });
    return () => {
      socket.off('sync_client_list', () => {
        onPaginationValueChange({
          page: 1,
          limit: 10,
          isPaginate: true
        });
      });
    };
  }, []);

  const getAllCollaborationUsersList = (params) => {
    dispatch(allClientsList(params));
  };

  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false,
      search: search
    };
    getAllCollaborationUsersList(paginationLimits);
    setPaginationData(paginationLimits);
  };

  const reDirectToClientPage = (clientId) => {
    socket.emit('addAccess', {
      user_id: professionalUserId,
      access_user_id: clientId
    });
    if (process.env.REACT_APP_MODE == 'LOCAL') {
      window.open(
        `http://${redirectDomain}:3333/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_blank'
      );
    } else {
      window.open(
        `http://${redirectDomain}/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_blank'
      );
    }
  };

  const onRemoveUserClick = () => {
    let clientArray;
    if (deleteUsers.length > 0) {
      clientArray = deleteUsers;
    } else {
      clientArray = selectedUsers.map(String);
    }
    dispatch(deleteClient({ remove_clients: clientArray, remove_entirely: true }))
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          setSelectedUsers([]);
          setDeleteUsers([]);
          handleCloseModel('removeUser');
        }
      });
  };

  const COLUMNS = [
    {
      label: (
        <CheckBox
          name="select-header"
          className={
            selectedUsers?.length > 1 && clientsRecord?.records.length !== selectedUsers?.length
              ? 'select-all'
              : ''
          }
          handleChange={() => handleSelectedUser('all')}
          values={{
            'select-header':
              clientsRecord?.records && clientsRecord?.records.length === selectedUsers.length
          }}
        />
      ),
      renderCell: (item) => (
        <CheckBox
          name="select-header"
          handleChange={(e) => {
            handleSelectedUser(item.id);
          }}
          values={{ 'select-header': selectedUsers.indexOf(item.id) > -1 }}
        />
      ),
      width: '30'
    },
    {
      label: 'First name',
      renderCell: (item) => item.first_name
    },
    {
      label: 'Last name',
      renderCell: (item) => item.last_name
    },
    { label: 'Email', renderCell: (item) => item.email },
    { label: 'Phone No.', renderCell: (item) => (item.phone_no ? item.phone_no : '-') },
    {
      label: 'Company',
      renderCell: (item) => (
        <label
          className={`${item?.company_details?.length ? 'cs-primary cursor-pointer' : ''}`}
          onClick={() => {
            if (item?.company_details?.length) {
              setViewCompaniesData(item?.company_details);
              handleOpenModel('viewCompanies');
            }
          }}>
          {item?.company_details?.length ? 'View' : '-'}
        </label>
      )
    },
    {
      label: 'User Assigned',
      renderCell: (item) => (
        <span
          className="cursor-pointer cs-primary"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedData(item);
            handleOpenModel('manageClient');
            setManageClientModelId(item.id);
          }}>
          {role === roles?.standard?.name ? ' View' : 'View & Manage'}
        </span>
      )
    },
    {
      label: 'Date Added',
      renderCell: (item) =>
        item?.is_verified && item?.is_profile_set && item?.is_initial_setup_done ? (
          moment(item.createdAt).format('MM/DD/YYYY')
        ) : (
          <span className="cs-regular-body-text-s cs-badge cs-warning">Pending</span>
        )
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        <div className="cs-table-icons">
          <span
            onClick={() => {
              if (!item?.company_details?.length) {
                handleOpenModel('removeUser');
                setDeleteUsers([item.id.toString()]);
              }
            }}
            className={`${!item?.company_details?.length ? 'cursor-pointer delete-outlined cs-delete-btn' : 'delete-outlined cs-delete-btn cs-disabled'}  `}>
            {item?.company_details?.length ? (
              <CapsyncToolTip
                Child={'delete-outlined'}
                placement={'top'}
                message={
                  'This individual cannot be removed because they are currently associated with the company.'
                }
                type="icon"
                size="14"
              />
            ) : (
              <CapsyncIcon title="delete-outlined" size="14" />
            )}
          </span>
          {!item?.is_profile_set && (
            <span className="collaboration-action-drop">
              <Dropdown className="cs-form-dropdown">
                <Dropdown.Toggle>
                  <span className="cursor-pointer cs-neutral-80">
                    <CapsyncIcon title="option-horizontal-filled" size="18" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleResendInvitation(item)}>
                    Resend invitation
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          )}
        </div>
      )
    },
    {
      label: '',
      renderCell: (item) => (
        <div className="text-end">
          <span
            className={
              item.is_verified && item?.is_profile_set && item?.is_initial_setup_done
                ? 'cs-neutral-80 cursor-pointer'
                : 'cs-neutral-80 cs-disabled'
            }
            onClick={(row) => {
              handleAction(item);
            }}>
            <CapsyncIcon title="chevron-right-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];

  const handleOpenModel = (key) => {
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });

    if (key === 'moveUser') {
      setIsIndividualClient('IndividualTable');
    }
  };

  const handleAddMoreClient = () => {
    setIsModelOpen({
      ...isModelOpen,
      manageClient: false,
      addClient: true
    });
  };

  const handleCloseModel = (key) => {
    if (key === 'addIndividual') {
      getAllCollaborationUsersList({
        page: clientsRecord.pagination.page,
        limit: clientsRecord.pagination.limit
      });
      dispatch(resetError());
    }
    if (key === 'addCompany') {
      getAllCollaborationUsersList({
        page: clientsRecord.pagination.page,
        limit: clientsRecord.pagination.limit
      });
      dispatch(resetError());
    }
    if (key === 'manageClient') setManageClientModelId(-1);
    if (key === 'removeUser') {
      getAllCollaborationUsersList({
        page: clientsRecord.pagination.page,
        limit: clientsRecord.pagination.limit
      });
      setSelectedUsers([]);
    }
    if (key === 'addClient') {
      return setIsModelOpen({
        ...isModelOpen,
        manageClient: true,
        [key]: false
      });
    }
    if (key === 'moveUser') {
      setIsIndividualClient('');
    }
    if (key === 'viewCompanies') {
      setViewCompaniesData([]);
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };
  const handleSelectedUser = (key) => {
    if (key === 'all') {
      if (clientsRecord.records.length === selectedUsers.length) {
        setSelectedUsers([]);
        setSelectedUsersDetails([]);
      } else {
        setSelectedUsers(clientsRecord.records.map((client) => client.id));
        setSelectedUsersDetails(clientsRecord.records.map((client) => client));
      }
    } else {
      const index = selectedUsers.indexOf(key);
      const matchingClients = clientsRecord.records.filter((client) => client.id === key);
      if (index !== -1) {
        setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
        setSelectedUsersDetails([
          ...selectedUsersDetails.slice(0, index),
          ...selectedUsersDetails.slice(index + 1)
        ]);
      } else {
        setSelectedUsers([...selectedUsers, key]);
        setSelectedUsersDetails([...selectedUsersDetails, ...matchingClients]);
      }
    }
  };
  const handleTableSearch = (searchText) => {
    setSearch(searchText);
    columnDetails.setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: columnDetails.rowsPerPage.value
    });
  };

  const startSearch = useDebounce(() => {
    dispatch(allClientsList({ ...paginationData, search }));
  }, 1000);

  useEffect(() => {
    startSearch();
  }, [paginationData, search]);

  const handleAction = (item) => {
    if (item.is_verified && item.is_profile_set && item?.is_initial_setup_done) {
      dispatch(addRecentlyViewedIndividual({ user_id: item.id }));
      reDirectToClientPage(item?.id);
    }
  };
  const handleExportData = async () => {
    if (!checkExport) {
      setCheckExport(true);
      const token = secureLocalStorage.getItem('token');
      axios({
        url: `${config.BASE_URL}client/export`,
        method: 'get',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
          if (response && response.status === 200) {
            setCheckExport(false);
            fileDownload(response.data, 'ClientData.pdf');
            toast.success(TOAST_MESSAGE.CLIENT_DATA_EXPORT_SUCCESS);
          } else {
            toast.error(TOAST_MESSAGE.CLIENT_DATA_EXPORT_ERROR);
          }
        })
        .catch((err) => {
          toast.error('Export Data :' + err.message);
        });
    }
  };
  const handleResendInvitation = async (item) => {
    dispatch(
      resendEmailVerification({ email: item.email, is_team_collaboration: true, firm_id: firmID })
    ).then((res) => {
      if (res.payload.status) {
        toast.success(res.payload.message);
      } else {
        toast.error(res.payload.message);
      }
    });
  };
  const [loading] = useOutletContext();

  return (
    <section className="individual-main-section main-section">
      <Container fluid>
        {loading ? (
          <SkeletonIndCompany />
        ) : (
          <Row>
            <div className="cs-section-top-bar financial-advisor-top-bar">
              <div className="client-top-bar">
                <div className="cs-dashboard-title">
                  <h1 className="cs-semi-bold-h1 cs-neutral-100">Individuals</h1>
                  <div className="cs-add-row">
                    {/* **********icons dropdown for mobile    */}
                    {selectedUsers.length ? (
                      <div className="add-account-drop for-flex-mob">
                        <NavDropdown
                          title={
                            <span className="cursor-pointer cs-primary dropdown-icon">
                              <CapsyncIcon title="option-vertical-filled" size="18" />
                            </span>
                          }
                          className="cs-action-btn">
                          <NavDropdown.Item onClick={() => handleOpenModel('moveUser')}>
                            <span className="cs-neutral-90">
                              <CapsyncIcon title="user-single-outlined" size="16" />
                            </span>
                            Assign
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => handleOpenModel('addCompany')}>
                            <span className="cs-neutral-90">
                              <CapsyncIcon title="company-outlined" size="16" />
                            </span>
                            Create Company
                          </NavDropdown.Item>
                          {!selectedUsersDetails.some((x) => x.company_details.length > 0) && (
                            <NavDropdown.Item onClick={() => handleOpenModel('removeUser')}>
                              <span>
                                <CapsyncIcon title="delete-outlined" size="16" />
                              </span>
                              Delete
                            </NavDropdown.Item>
                          )}
                        </NavDropdown>
                      </div>
                    ) : null}
                    <span
                      className="cs-neutral-10 mobile-add-acc-icon"
                      onClick={() => handleOpenModel('addIndividual')}>
                      <CapsyncIcon title="add-filled" size="18" />
                    </span>
                  </div>
                </div>

                <Searchbar
                  placeholder={'Search by individual name'}
                  search={search}
                  setSearch={handleTableSearch}
                />
                <div className="cs-add-row">
                  {selectedUsers.length ? (
                    <div className="cs-action-btn for-flex-des">
                      <span
                        className="cs-primary"
                        title="Close"
                        onClick={() => {
                          setSelectedUsers([]);
                          setSelectedUsersDetails([]);
                        }}>
                        <CapsyncIcon title="close-outlined" size="14" />
                      </span>
                      <span
                        title="Assign"
                        onClick={() => {
                          handleOpenModel('moveUser');
                        }}>
                        <CapsyncIcon title="user-single-outlined" size="16" />
                      </span>
                      <span
                        title="Create Company"
                        onClick={() => {
                          handleOpenModel('addCompany');
                        }}>
                        <CapsyncIcon title="company-outlined" size="16" />
                      </span>
                      <span
                        className={`cs-danger ${selectedUsersDetails.some((x) => x.company_details.length > 0) ? ' cs-disabled' : ''}`}
                        title="Delete"
                        onClick={() => {
                          !selectedUsersDetails.some((x) => x.company_details.length > 0) &&
                            handleOpenModel('removeUser');
                        }}>
                        <CapsyncIcon title="delete-outlined" size="16 " />
                      </span>
                    </div>
                  ) : null}
                  <Button
                    onClick={() => handleOpenModel('addIndividual')}
                    className="cs-btn-icon-primary lg-btn cs-regular-h5 add-btn ">
                    <span className="cs-neutral-10 cs-icon">
                      <CapsyncIcon title="add-filled" size="18" />
                    </span>
                    <span className="cs-nav-text">Add Individual</span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="individual-table">
              <CustomTable
                columns={COLUMNS}
                data={clientsRecord && clientsRecord.records ? clientsRecord.records : []}
                isPaginate={true}
                totalRecords={
                  clientsRecord?.pagination?.itemCount
                    ? clientsRecord.pagination.itemCount
                    : clientsRecord?.records?.length
                }
                onPaginationValueChange={onPaginationValueChange}
                setColumnDetails={setColumnDetails}
                fromIndividual={true}
                handleExportData={handleExportData}
                checkExport={checkExport}
              />
            </div>
            {isModelOpen.addIndividual && (
              <AddClientModal
                typeModal="IndividualClient"
                handleCloseModel={() => handleCloseModel('addIndividual')}
                setClientAdded={setClientAdded}
              />
            )}
            {isModelOpen.manageClient && (
              <ManageIndividualClientModel
                handleCloseModel={() => handleCloseModel('manageClient')}
                userId={manageClientModelId}
                selectedData={selectedData}
                handleAddMoreClient={handleAddMoreClient}
                fromTable={'individualClient'}
              />
            )}
            {isModelOpen.removeUser && (
              <DeleteModal
                show={isModelOpen.removeUser}
                onHandleClose={() => handleCloseModel('removeUser')}
                onHandleConfirm={onRemoveUserClick}
                deleteBodyText="You are going to delete this entry, Once it is deleted, you will be not able to recover this data"
                deleteButtonText="Delete"
              />
            )}
            {isModelOpen.moveUser && (
              <ChooseUserModal
                handleCloseModel={() => handleCloseModel('moveUser')}
                clientIds={selectedUsers}
                isIndividualClient={isIndividualClient}
                setSelectedUsers={setSelectedUsers}
                type="IndividualClient"
              />
            )}
            {isModelOpen.addCompany && (
              <EditCompanyModal
                handleCloseModel={() => handleCloseModel('addCompany')}
                setClientAdded={setClientAdded}
                selectedUsersDetails={selectedUsersDetails}
                setSelectedUsersDetails={setSelectedUsersDetails}
                setSelectedUsers={setSelectedUsers}
                isSelectedUsers={true}
                professionalUserId={user.id.toString()}
              />
            )}
            {isModelOpen.viewCompanies && (
              <ViewCompaniesModal
                companies={viewCompaniesData}
                handleCancel={() => handleCloseModel('viewCompanies')}
              />
            )}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default IndividualClient;
