import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/* ============================== TEXT AREA ============================== */
const TextAreaBox = ({ label, name, values, onChange, disabled = false, errors, touched }) => {
  return (
    <Form.Group className="cs-form-group cs-form-textarea">
      {label && <Form.Label>{label}</Form.Label>}
      <textarea
        autoComplete="off"
        type="text"
        name={name}
        value={values[name]}
        className={values[name] ? 'cs-textarea cs-input-field-active' : 'cs-textarea'}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === ' ' && e.target.selectionStart === 0) {
            e.preventDefault();
          }
        }}></textarea>
      {errors[name] && touched[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
TextAreaBox.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  touched: PropTypes.object,
  disabled: PropTypes.bool
};

export default TextAreaBox;
