import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';

// COMPONENT IMPORTS
import CustomTable from '../../../components/CustomTable';
import SkeletonAuditlog from '../../components/skeleton/SkeletonAuditlog';

// HOOKS IMPORTS
import useDebounce from '../../../hooks/useDebounce';

// API
import { useUserID } from '../../../slices/authSlice';
import { getAuditLogs } from '../../../slices/auditLogsSlice';

/* ============================== AUDIT LOGS ============================== */
const AuditLogs = () => {
  const dispatch = useDispatch();
  const userId = useUserID();

  const { user } = useSelector((state) => state.auth);
  const { individual } = useSelector((state) => state.auditLogs?.auditLogs ?? []);
  const { individualPagination } = useSelector((state) => state.auditLogs?.auditLogs ?? 0);

  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState({});

  const startSearch = useDebounce(() => {
    dispatch(
      getAuditLogs({
        userId,
        roleId: user && user.role_id,
        page: paginationData.page,
        limit: paginationData.limit,
        isPaginate: paginationData.limit === 'all' ? false : true,
        search
      })
    );
  }, 1000);

  useEffect(() => {
    startSearch();
  }, [paginationData, search]);

  const COLUMNS = [
    {
      label: 'Date',
      renderCell: (item) => {
        const date = new Date(item.createdAt);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return <span>{`${day}/${month}/${year}`}</span>;
      }
    },
    {
      label: 'Time',
      renderCell: (item) => {
        return <span>{moment(item.createdAt).format('HH:mm')}</span>;
      }
    },
    {
      label: 'User Name',
      renderCell: (item) => <span>{item.firstName + ' ' + item.lastName}</span>
    },
    { label: 'Categories', renderCell: (item) => item.module_name },
    { label: 'Sub Categories', renderCell: (item) => item.sub_module_name },
    { label: 'Events', renderCell: (item) => item.title }
  ];

  const [loading] = useOutletContext();

  return (
    <div className="cs-setting-page cs-audit-logs-sec">
      {loading ? (
        <SkeletonAuditlog />
      ) : (
        <CustomTable
          columns={COLUMNS}
          totalRecords={
            paginationData?.limit === 'all' ? individual?.length : individualPagination?.itemCount
          }
          data={individual}
          isPaginate={true}
          onPaginationValueChange={setPaginationData}
          hasSearchBar={true}
          search={search}
          setSearch={setSearch}
        />
      )}
    </div>
  );
};

export default AuditLogs;
