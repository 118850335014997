import React from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import AuthHead from './AuthHead';
import AuthBanner from './AuthBanner';
import CONFIG from '../constants/config';
import CapsyncIcon from '../components/CapsyncIcon';
import InputBox from '../components/FormFields/InputBox';
import { confirmPasswordField, passwordField } from '../validations';

// HOOKS IMPORTS
import useRemoveServerError from '../hooks/useRemoveServerError';

// API
import { useApiStatus, useServerMessage, userChangePassword } from '../slices/authSlice';

/* ============================== CHANGE PASSWORD ============================== */
const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const message = useServerMessage();
  const status = useApiStatus();

  useRemoveServerError();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: yup.object().shape({
      password: passwordField(),
      confirm_password: confirmPasswordField()
    }),
    onSubmit: async (values) => {
      values.reset_password_token = token;
      const res = await dispatch(userChangePassword(values)).unwrap();
      if (res.status) {
        navigate('/');
        useRemoveServerError();
      }
    }
  });

  return (
    <section className="auth-section thankyou-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <AuthHead title={'Reset Password'} description={``} />
              <div className="cs-auth-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        isSignUp
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        name="confirm_password"
                        type="password"
                        placeholder="Enter Confirm password"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  {message && (
                    <span
                      className={`cs-regular-body-text-m ${status === CONFIG.STATUS.SUCCESS ? 'cs-success' : 'cs-danger'}`}>
                      <CapsyncIcon
                        title={
                          status === CONFIG.STATUS.SUCCESS
                            ? 'verification-outlined'
                            : 'declined-outlined'
                        }
                        size="16"
                      />
                      {message}
                    </span>
                  )}
                  <div className="auth-footer">
                    <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                      Reset password
                    </Button>
                  </div>
                </Form>
                <span className="auth-main-link cs-regular-body-text-m">
                  New to CapSync?
                  <Link to="/sign-up" className="cs-primary">
                    &nbsp;Sign up
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChangePassword;
