import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// COMPONENT IMPORTS
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import MFASetUp from '../auth/MFASetUp';
import ThankYou from '../auth/ThankYou';
import GoogleSetupQR from '../auth/GoogleSetupQR';
import ForgotPassword from '../auth/ForgotPassword';
import ChangePassword from '../auth/ChangePassword';
import PageNotFound from '../components/PageNotFound';
import VerificationPin from '../auth/VerificationPin';
import BusinessForm from '../auth/SignUp/BusinessForm';
import EmailVerification from '../auth/EmailVerification';
import Confirmed from '../auth/SignUp/BusinessForm/Confirmed';

const ProtectedRoutes = () => {
  const token = secureLocalStorage.getItem('token');
  const { mfa } = useSelector((state) => state.auth);

  if (mfa && mfa.verified && mfa.verified && token) {
    return <Navigate to="/dashboard" replace />;
  } else {
    return <Outlet />;
  }
};

/* ============================== AUTH ROUTES ============================== */
const AuthRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <SignIn />
    },
    {
      path: 'sign-in',
      element: <Navigate to="/" replace />
    },
    {
      path: 'sign-up',
      element: <SignUp />
    },
    {
      path: 'sign-up/:token',
      element: <SignUp />
    },
    {
      path: 'mfa-setup',
      element: <MFASetUp />
    },
    {
      path: 'verification-pin',
      element: <VerificationPin />
    },
    {
      path: 'verify-business/:token',
      element: <BusinessForm />
    },
    {
      path: 'confirmed-business',
      element: <Confirmed />
    },
    {
      path: 'email-verification',
      element: <EmailVerification />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'forgot-password/:token',
      element: <ChangePassword />
    },
    {
      path: 'thank-you',
      element: <ThankYou />
    },
    {
      path: 'authenticator-setup',
      element: <GoogleSetupQR />
    },
    {
      path: 'error-page',
      element: <PageNotFound />
    }
  ]
};

export default AuthRoutes;
