/* eslint camelcase: 0 */
import * as yup from 'yup';

// COMPONENT IMPORTS
import {
  cityField,
  emailField,
  firstNameField,
  lastNameField,
  phoneField,
  streetNameField,
  zipcodeField
} from '../../validations';

const AddAccountValidationSchema = yup.object().shape({
  first_name: firstNameField(),
  last_name: lastNameField(),
  email: emailField(),
  permission: yup.object().required('Permission Required')
});

export { AddAccountValidationSchema };
const AddIndividualClientValidationSchema = yup.object().shape({
  first_name: firstNameField(),
  last_name: lastNameField(),
  email: emailField()
});

export const EditProfileValidationSchema = yup.object().shape({
  first_name: firstNameField(),
  last_name: lastNameField(),
  email: emailField(),
  phone_no: phoneField(),
  street_name: streetNameField(),
  city: cityField(),
  zip_code: zipcodeField()
});

export { AddIndividualClientValidationSchema };
