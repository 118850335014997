import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import config from '../../../constants/config';
import { AssociateIndividual } from './AssociateCompany';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { TOAST_MESSAGE } from '../../../constants/message';
import InputBox from '../../../components/FormFields/InputBox';
import { VALIDATION_MESSAGE } from '../../../constants/message';
import { companyNameField, emailField, firstNameField, lastNameField } from '../../../validations';

// API
import { addCompany, resetError } from '../../../slices/clientSlice';
import { allCollaborationUsersList } from '../../../slices/collaborationSlice';

/* ============================== CREATE COMPANY MODAL ============================== */
const CreateCompanyModal = ({
  handleCloseModel,
  setClientAdded,
  companyDetails,
  setIsEditModal,
  isEditModal,
  setAddPartner,
  editCompany,
  professionalUserId,
  isSelectedUsers,
  setSelectedUsersDetails,
  setSelectedUsers,
  setOpenIndividualModal,
  setOpenCompanyModal,
  formSidebar,
  selectedUsersDetails,
  editCompanyDetails,
  handleOpenModel
}) => {
  const dispatch = useDispatch();
  const { status, message } = useSelector((state) => state.client);
  const { usersRecord } = useSelector((state) => state.collaboration);

  const [formError, setFormError] = useState('');
  const [dropdownErr, setDropdownErr] = useState('');
  const [type, setType] = useState({});
  const [selectedCompanyName, setSelectedCompanyName] = useState([]);
  const [dropdownValidation, setDropdownValidation] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyError, setCompanyError] = useState('');
  // const [checkCount, setCheckCount] = useState([]);
  const [partners, setPartners] = useState([{ email: '', first_name: '', last_name: '', id: 0 }]);
  const [validation, setValidation] = useState({
    msg: '',
    color: ''
  });
  const [userType, setUserType] = useState('Company');
  const { values, touched, handleSubmit } = useFormik({
    initialValues: {
      partners
    },
    // validationSchema: AddIndividualClientValidationSchema,
    onSubmit: async (values) => {
      try {
        let isError = false;
        const clientList = [];
        if (Object.keys(type).length === 0 || companyName === '') {
          if (Object.keys(type).length === 0) setDropdownErr('Please select a user');
          if (companyName === '') setCompanyError(VALIDATION_MESSAGE.COMPANY_NAME_REQUIRED);
          isError = true;
        } else {
          setDropdownErr('');
          setCompanyError('');
        }
        for (const client of partners) {
          const firstName = client.first_name;
          const lastName = client.last_name;
          const email = client.email;
          let firstNameError = '';
          let lastNameError = '';
          let emailError = '';
          // check first name - client.last_name !== '' || client.email !== '' || client.first_name !== ''
          if (
            !selectedCompanyName.length ||
            [firstName, lastName, email].find((val) => val !== '')
          ) {
            const fNameValidationSchema = firstNameField();
            try {
              await fNameValidationSchema.validate(firstName);
              firstNameError = '';
            } catch (error) {
              isError = true;

              firstNameError = error.message;
            }
            // check last name
            const lNameValidationSchema = lastNameField();
            try {
              await lNameValidationSchema.validate(lastName);
              lastNameError = '';
            } catch (error) {
              isError = true;
              lastNameError = error.message;
            }

            // check email
            const emailValidationSchema = emailField();
            try {
              await emailValidationSchema.validate(email);
              emailError = '';
            } catch (error) {
              isError = true;
              emailError = error.message;
            }

            if (firstNameError || lastNameError || emailError) {
              clientList.push({
                first_name: firstName,
                last_name: lastName,
                email: email,
                fnameError: firstNameError,
                lnameError: lastNameError,
                emailError: emailError
              });
            } else {
              clientList.push({
                first_name: firstName,
                last_name: lastName,
                email: email
              });
            }
          } else {
            clientList.push({
              first_name: firstName,
              last_name: lastName,
              email: email
            });
          }
        }
        if (isError === true) {
          setPartners(clientList);
          return false;
        }

        const clientListFilter = clientList.filter(
          (client) => client.first_name !== '' && client.last_name !== '' && client.email !== ''
        );
        const reqData = {
          clients: clientListFilter,
          professional_user_id: type.id.toString(),
          company_name: companyName,
          is_existing_individual: selectedCompanyName?.length > 0 ? true : false,
          individual_ids:
            selectedCompanyName?.length > 0
              ? selectedCompanyName.map((client) => client.toString())
              : []
        };
        // dispatch(addCompany(reqData)).then(() => setShowStatus(true));
        const response = await dispatch(addCompany(reqData)).unwrap();
        if (response?.data) {
          // setCheckCount(response?.data?.already_present_clients);
          setShowStatus(true);
        }
      } catch (e) {
        console.log('error onSubmit', e);
        setFormError(e);
        setShowStatus(false);
      }
    }
  });

  useEffect(() => {
    if (isChecked === true) {
      const clientList = [];
      for (const client of partners) {
        const firstName = client.first_name;
        const lastName = client.last_name;
        const email = client.email;
        // check first name - client.last_name !== '' || client.email !== '' || client.first_name !== ''
        if (firstName || lastName || email) {
          clientList.push(client);
        } else {
          clientList.push({
            first_name: firstName,
            last_name: lastName,
            email: email,
            fnameError: '',
            lnameError: '',
            emailError: ''
          });
        }
      }
      setPartners(clientList);
    }
  }, [isChecked]);

  useEffect(() => {
    if (showStatus) {
      if (status === config.STATUS.SUCCESS) {
        // if (checkCount.length > 0) {
        //   handleOpenModel();
        // }
        handleCloseModel();
        setFormError('');
        setDropdownErr('');
        toast.success(TOAST_MESSAGE.USER_ADDED_SUCCESS);
        setClientAdded && setClientAdded(true);
        if (isEditModal && isSelectedUsers) {
          setSelectedUsersDetails([]);
          setSelectedUsers([]);
        }
      } else {
        status === config.STATUS.ERROR && setFormError(message);
      }
    }
    return () => {
      setShowStatus(false);
    };
  }, [showStatus]);

  useEffect(() => {
    getAllCollaborationUsersList({
      page: false,
      limit: false,
      include_logged_user: true
    });
  }, []);

  const getAllCollaborationUsersList = (params) => {
    dispatch(allCollaborationUsersList(params));
  };

  useEffect(() => {
    if (formError !== '') {
      setFormError('');
      dispatch(resetError());
    }
  }, [values]);

  const recordsArray = usersRecord?.records?.filter((user) => user?.is_profile_set) || [];
  const checkType = (event) => {
    setType(event);
    setDropdownErr('');
  };
  const addPartner = (e) => {
    e.preventDefault();
    const isAllFieldsFilled = partners.every(
      (partner) =>
        partner.email.trim() !== '' &&
        partner.first_name.trim() !== '' &&
        partner.last_name.trim() !== ''
    );

    setTimeout(() => {
      if (isAllFieldsFilled) {
        setPartners([...partners, { email: '', first_name: '', last_name: '', id: 0 }]);
      } else {
        setValidation({
          msg: '*Please fill all partner details',
          color: 'text-danger'
        });
      }
    }, 500);
    setFormError('');
  };

  const handleCompanyName = async (val) => {
    const emailValidationSchema = companyNameField();
    try {
      await emailValidationSchema.validate(val);
      setCompanyError('');
    } catch (error) {
      setCompanyError(error.message);
    }
    setCompanyName(val);
    setFormError('');
  };

  const fieldChange = async (val, params, type) => {
    const inputData = [...partners];
    setValidation({
      msg: '',
      color: ''
    });

    if (type === 'email') {
      inputData[params].email = val;
      const emailValidationSchema = emailField();
      try {
        await emailValidationSchema.validate(val);
        inputData[params].emailError = '';
      } catch (error) {
        inputData[params].emailError = error.message;
      }
      setPartners(inputData);
    } else if (type === 'first_name') {
      inputData[params].first_name = val;
      const firstNameValidationSchema = firstNameField();
      try {
        await firstNameValidationSchema.validate(val);
        inputData[params].fnameError = '';
      } catch (error) {
        inputData[params].fnameError = error.message;
      }
      setPartners(inputData);
    } else if (type === 'last_name') {
      inputData[params].last_name = val;
      const lastNameValidationSchema = lastNameField();
      try {
        await lastNameValidationSchema.validate(val);
        inputData[params].lnameError = '';
      } catch (error) {
        inputData[params].lnameError = error.message;
      }
      setPartners(inputData);
    } else {
      inputData[params].last_name = val;
      setPartners(inputData);
    }
    setFormError('');
  };

  const removeField = (params) => {
    if (partners.length > 1) {
      const fetchData = [...partners];
      fetchData.splice(params, 1);
      setPartners(fetchData);
    }
  };

  const handleCloseEditButton = () => {
    setAddPartner(false);
  };

  const handleSubmitEditButton = async () => {
    try {
      let totalClient = [];
      totalClient = [...partners];
      let isError = false;
      const clientList = [];
      for (const client of totalClient) {
        const firstName = client.first_name;
        const lastName = client.last_name;
        const email = client.email;
        let firstNameError = '';
        let lastNameError = '';
        let emailError = '';
        // check first name - client.last_name !== '' || client.email !== '' || client.first_name !== ''
        if (!selectedCompanyName.length || [firstName, lastName, email].find((val) => val !== '')) {
          const fNameValidationSchema = firstNameField();
          try {
            await fNameValidationSchema.validate(firstName);
            firstNameError = '';
          } catch (error) {
            isError = true;

            firstNameError = error.message;
          }
          // check last name
          const lNameValidationSchema = lastNameField();
          try {
            await lNameValidationSchema.validate(lastName);
            lastNameError = '';
          } catch (error) {
            isError = true;
            lastNameError = error.message;
          }

          // check email
          const emailValidationSchema = emailField();
          try {
            await emailValidationSchema.validate(email);
            emailError = '';
          } catch (error) {
            isError = true;
            emailError = error.message;
          }

          if (firstNameError || lastNameError || emailError) {
            clientList.push({
              first_name: firstName,
              last_name: lastName,
              email: email,
              fnameError: firstNameError,
              lnameError: lastNameError,
              emailError: emailError
            });
          } else {
            clientList.push({
              first_name: firstName,
              last_name: lastName,
              email: email
            });
          }
        } else {
          clientList.push({
            first_name: firstName,
            last_name: lastName,
            email: email
          });
        }
      }
      if (isError === true) {
        setPartners(clientList);
        return false;
      }

      const clientListFilter = clientList.filter(
        (client) => client.first_name !== '' && client.last_name !== '' && client.email !== ''
      );

      const selectedIndividualId = selectedUsersDetails?.map((client) => client.id);

      const reqData = {
        clients: clientListFilter,
        company_name: isEditModal && isEditModal ? editCompany : companyName,
        is_existing_individual:
          (selectedIndividualId && selectedIndividualId) || selectedCompanyName?.length > 0
            ? true
            : false
      };

      if (isEditModal && !isSelectedUsers) {
        reqData.company_id = companyDetails.id.toString();
      }

      if (isEditModal && !isSelectedUsers) {
        reqData.professional_user_id = companyDetails.user_id.toString();
        reqData.individual_ids = selectedCompanyName?.length > 0 ? selectedCompanyName : [];
      } else if (isSelectedUsers) {
        reqData.professional_user_id = professionalUserId;
        reqData.individual_ids =
          selectedCompanyName?.length > 0
            ? [...selectedIndividualId, ...selectedCompanyName]
            : !selectedCompanyName
              ? [...selectedIndividualId]
              : [];
      } else {
        reqData.professional_user_id = type.id.toString();
        reqData.individual_ids = selectedCompanyName?.length > 0 ? selectedCompanyName : [];
      }

      // dispatch(addCompany(reqData)).then(() => setShowStatus(true));
      const response = await dispatch(addCompany(reqData)).unwrap();
      if (response?.data) {
        // setCheckCount(response?.data?.already_present_clients);
        setShowStatus(true);
      }
    } catch (e) {
      setFormError(e);
      setShowStatus(false);
    }
  };

  const handleUserType = (event) => {
    setUserType(event);
    if (event === 'Individual') {
      setOpenIndividualModal(true);
      setOpenCompanyModal(false);
    }
    if (event === 'Company') {
      setOpenIndividualModal(false);
      setOpenCompanyModal(true);
    }
  };
  const userTypeArray = ['Individual', 'Company'];

  const isAddPartnerDisabled = () => {
    return !partners.every(
      (partner) =>
        partner.email.trim() !== '' &&
        partner.first_name.trim() !== '' &&
        partner.last_name.trim() !== '' &&
        partner.emailError === ''
    );
  };
  return (
    <Modal
      title={isEditModal ? 'Add Partners to company' : 'Create Company'}
      show={true}
      className="cs-md-modal cs-new-user-modal"
      body={
        <React.Fragment>
          {!isEditModal && (
            <React.Fragment>
              {formSidebar && (
                <Row>
                  <Col lg={12} sm={12}>
                    <Form.Group>
                      <Form.Label className="cs-neutral-100">Select user type</Form.Label>

                      <Dropdown className="cs-form-dropdown">
                        <Dropdown.Toggle className="cs-regular-sub-heading-s cs-neutral-70">
                          {userType.length === 0 ? 'Select User ' : userType}

                          <span className="cs-neutral-90">
                            <CapsyncIcon title="chevron-down-outlined" size="18" />
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {userTypeArray.map((record) => (
                            <Dropdown.Item
                              key={record.id}
                              onClick={() => handleUserType(record)}
                              className={
                                record === userType
                                  ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s cs-neutral-90'
                                  : 'cs-regular-sub-heading-s cs-neutral-90 cs-content-title '
                              }>
                              {record}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="cs-neutral-100">Select user</Form.Label>

                    <Dropdown className="cs-form-dropdown">
                      <Dropdown.Toggle className="cs-regular-sub-heading-s cs-neutral-70">
                        {Object.keys(type).length === 0 ? 'Select User ' : type.display_name}

                        <span className="cs-neutral-90">
                          <CapsyncIcon title="chevron-down-outlined" size="18" />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="cs-scroller">
                          {recordsArray.map((record) => (
                            <Dropdown.Item
                              key={record.id}
                              onClick={() => checkType(record)}
                              className={
                                record.id === type.id
                                  ? 'dropdown-item dropdown-item-active cs-regular-sub-heading-s cs-neutral-90'
                                  : 'cs-regular-sub-heading-s cs-neutral-90 cs-content-title '
                              }>
                              {record.display_name}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                      <span className="form-error-msg cs-light-body-text-s cs-danger">
                        {dropdownErr}
                      </span>
                    </Dropdown>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="company-input">
                    <InputBox
                      label="Company name"
                      name="company_name"
                      className={companyName ? 'cs-input-field-active' : ''}
                      values={companyName}
                      errors={companyError}
                      touched={touched}
                      onChange={(e) => handleCompanyName(e.target.value)}
                    />
                    {companyError && blur && (
                      <span className="form-error-msg cs-light-body-text-s cs-danger">
                        {companyError}
                      </span>
                    )}
                    {status === 'error' && formError.includes('company_name') && !companyError && (
                      <span className="form-error-msg cs-light-body-text-s cs-danger">
                        {VALIDATION_MESSAGE.COMPANY_NAME_REQUIRED}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}

          {/* {isEditModal && ( */}
          <div className="cs-partner-details">
            <span className="cs-regular-body-text-m cs-neutral-80">Enter Partners details</span>
          </div>
          {/* )} */}
          <Form className="cs-repeated-scroller">
            {partners.map((iterator, index) => {
              return (
                <React.Fragment key={index}>
                  <Row>
                    <span className="company-modal-line">
                      {partners.length > 1 && index !== 0 && <hr className="cs-neutral-30" />}
                    </span>
                    {partners.length > 1 && index !== 0 && (
                      <div className="company-popup-remove" onClick={() => removeField(index)}>
                        <Button
                          type="submit"
                          className="cs-btn-icon-tertiary cs-danger cs-regular-h5">
                          <span className="cs-danger">
                            <CapsyncIcon title="delete-filled" size="16" />
                          </span>
                          Remove
                        </Button>
                      </div>
                    )}

                    <Col md={6}>
                      <Form.Group
                        className={`cs-form-group ${iterator.fnameError ? '' : ''}`}
                        controlId={`validationFormik03-${index}`}>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          value={iterator.first_name}
                          className={iterator.first_name ? 'cs-input-field-active' : ''}
                          onChange={(event) => fieldChange(event.target.value, index, 'first_name')}
                          onKeyDown={(e) => {
                            if (
                              (e.key === ' ' && e.target.selectionStart === 0) ||
                              /[0-9]/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {iterator.fnameError && (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {iterator.fnameError}
                          </span>
                        )}
                        {status === 'error' &&
                          (formError.includes('first_name') || formError.includes('partner')) &&
                          !iterator.fnameError &&
                          !iterator.first_name && (
                            <span className="form-error-msg cs-light-body-text-s cs-danger">
                              {VALIDATION_MESSAGE.FIRST_NAME_REQUIRED}
                            </span>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className={`cs-form-group ${iterator.lnameError ? '' : ''}`}
                        controlId={`validationFormik03-${index}`}>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          value={iterator.last_name}
                          className={iterator.last_name ? 'cs-input-field-active' : ''}
                          autoComplete="off"
                          onChange={(event) => fieldChange(event.target.value, index, 'last_name')}
                          onKeyDown={(e) => {
                            if (
                              (e.key === ' ' && e.target.selectionStart === 0) ||
                              /[0-9]/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {iterator.lnameError && (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {iterator.lnameError}
                          </span>
                        )}
                        {status === 'error' &&
                          (formError.includes('last_name') || formError.includes('partner')) &&
                          !iterator.fnameError &&
                          !iterator.last_name && (
                            <span className="form-error-msg cs-light-body-text-s cs-danger">
                              {VALIDATION_MESSAGE.LAST_NAME_REQUIRED}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Group
                        className={`cs-form-group last-form-field${iterator.emailError ? '' : ''}`}
                        controlId={`validationFormik03-${index}`}>
                        <Form.Label>Email</Form.Label>

                        <Form.Control
                          type="text"
                          autoComplete="off"
                          value={iterator.email}
                          className={iterator.email ? 'cs-input-field-active' : ''}
                          onChange={(event) => fieldChange(event.target.value, index, 'email')}
                          onKeyDown={(e) => {
                            if (e.key === ' ' && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {iterator.emailError && (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {iterator.emailError}
                          </span>
                        )}
                        {(formError.includes('email') || formError.includes('partner')) &&
                        status === 'error' &&
                        !iterator.email ? (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {iterator.emailError || VALIDATION_MESSAGE.EMAIL_REQUIRED}
                          </span>
                        ) : (
                          formError.includes('Email') &&
                          status === 'error' && (
                            <span className="form-error-msg cs-light-body-text-s cs-danger">
                              {iterator.emailError || VALIDATION_MESSAGE.EMAIL_REQUIRED}
                            </span>
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            })}
            <div className="view-and-manage-container add-more-btn">
              <Button
                type="submit"
                className="cs-btn-icon-tertiary cs-regular-h5"
                onClick={addPartner}
                disabled={isAddPartnerDisabled()}>
                <span className="cs-primary">
                  <CapsyncIcon title="add-filled" size="16" />
                </span>
                Add more partners
              </Button>
              <span className="form-error-msg cs-light-body-text-s cs-danger">
                {validation.msg}
              </span>
            </div>
            {/* )} */}
          </Form>
          <div style={{ position: 'relative' }}>
            <AssociateIndividual
              setSelectedCompanyName={setSelectedCompanyName}
              setDropdownValidation={setDropdownValidation}
              dropdownValidation={dropdownValidation}
              setIsChecked={setIsChecked}
              isChecked={isChecked}
              selectedUsersDetails={selectedUsersDetails}
              editCompanyDetails={editCompanyDetails}
              selectedCompanyName={selectedCompanyName}
            />
            {/* {status === 'error' &&
              !formError.includes('first_name') &&
              !formError.includes('last_name') &&
              !formError.includes('email') &&
              !formError.includes('Email') &&
              !formError.includes('company_name') && (
                <span className="form-error-msg cs-light-body-text-s cs-danger">{formError}</span>
              )} */}
            {status === 'error' && (
              <span className="form-error-msg cs-light-body-text-s cs-danger cs-create-company-modal-error">
                {formError}
              </span>
            )}
          </div>
        </React.Fragment>
      }
      saveButtonLabel={isChecked ? 'Submit' : 'Send Invitation'}
      cancelButtonLabel={'Cancel'}
      handleOnSave={isEditModal ? handleSubmitEditButton : handleSubmit}
      handleOnCancel={isEditModal ? handleCloseEditButton : handleCloseModel}
      isCloseButton={false}
      disabled={(isChecked && selectedCompanyName?.length === 0) || formError}
    />
  );
};

// PROPS TYPE
CreateCompanyModal.propTypes = {
  handleCloseModel: PropTypes.func,
  setClientAdded: PropTypes.func,
  companyDetails: PropTypes.array,
  setIsEditModal: PropTypes.func,
  isEditModal: PropTypes.bool,
  setAddPartner: PropTypes.bool,
  editCompany: PropTypes.string,
  professionalUserId: PropTypes.string,
  isSelectedUsers: PropTypes.bool,
  setSelectedUsersDetails: PropTypes.func,
  setSelectedUsers: PropTypes.func,
  setOpenIndividualModal: PropTypes.func,
  setOpenCompanyModal: PropTypes.func,
  formSidebar: PropTypes.bool,
  selectedUsersDetails: PropTypes.array,
  editCompanyDetails: PropTypes.array,
  handleOpenModel: PropTypes.func
};

export default CreateCompanyModal;
