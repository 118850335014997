import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== TOOLTIP ============================== */
const CapsyncToolTip = ({ Child, placement, message, type, size, width, trigger }) => {
  const location = useLocation();
  const pathname = location.pathname.split('/');

  const triggers = trigger === 'click' ? ['click', 'focus'] : ['hover', 'focus'];

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      trigger={triggers}
      overlay={
        <Tooltip
          style={{ maxWidth: width }}
          className={pathname[3] === 'accreditedInvestor' ? 'accredited-investor-tooltip' : ''}>
          {message}
        </Tooltip>
      }>
      <span>{type === 'text' ? Child : <CapsyncIcon title={Child} size={size} />}</span>
    </OverlayTrigger>
  );
};

// PROPS TYPE
CapsyncToolTip.propTypes = {
  Child: PropTypes.string,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string
};

export default CapsyncToolTip;
