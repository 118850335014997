import * as React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer, Flip } from 'react-toastify';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AppRoutes from './routes';

// CSS IMPORTS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-number-input/style.css';

import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/dashboard.css';

// COMPONENT IMPORTS
import { socket } from './config/Socket';
import { localObjectClear } from './utils/system';
import InactivityLogoutModal from './components/InactivityLogoutModal';

// API
import { authUserLogout, useUserID } from './slices/authSlice';

const INACTIVITY_TIMER = process.env.REACT_APP_INACTIVITY_TIMEOUT
  ? process.env.REACT_APP_INACTIVITY_TIMEOUT * 1000 * 60
  : 1000 * 60 * 15;
const PROMPT_TIMER = process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT
  ? process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT * 60
  : 60 * 2;

var pjson = require('../package.json');
const version = process.env.REACT_APP_VERSION + '-' + pjson.commit_id;
console.log('App version & commit id:', version);
/* ============================== APP ============================== */
function App() {
  const isSocketInitialized = React.useRef(false);
  const userID = useUserID();
  const dispatch = useDispatch();

  const renderRoutes = React.useMemo(() => {
    const router = createBrowserRouter(AppRoutes);
    return <RouterProvider router={router} />;
  }, []);

  const [countdown, setCountdown] = React.useState(INACTIVITY_TIMER); // 2 mins

  // Manage Inactivity time
  const { getRemainingTime, activate, isLastActiveTab } = useIdleTimer({
    // onIdle,
    // onActive,
    // onAction,
    timeout: INACTIVITY_TIMER, // use for total in activity timeout
    syncTimers: 200,
    startOnMount: true,
    throttle: 10000,
    // onPrompt: () => handleInactivityTimeout(),
    promptBeforeIdle: 1000 * PROMPT_TIMER, // timeout - promptBeforeIdle -> call onPrompt
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'touchstart',
      'touchmove'
      // 'visibilitychange'
    ]
  });

  const handleStay = () => {
    activate();
    // setShowModal(false);
  };

  const handleLogoutUser = () => {
    dispatch(authUserLogout());
    localObjectClear();
    window.location.reload();
  };

  React.useEffect(() => {
    if (userID && !isSocketInitialized.current) {
      socket.emit('updateSocket', { user_id: userID });
      isSocketInitialized.current = true;
    }

    const handleLoad = () => {
      // Manage tab close session

      if (userID) {
        const lastTimeStamp = window.localStorage.getItem('lastTimeStamp');

        const duration = moment.duration(moment().diff(lastTimeStamp));
        const durationInMinutes = duration.asMinutes();

        const tabCloseTimeoutLimit = process.env.REACT_APP_TAB_CLOSE_TIMEOUT_LIMIT || 5;

        if (
          durationInMinutes &&
          tabCloseTimeoutLimit < durationInMinutes &&
          !window.location.pathname.startsWith('/individual/quickbooks/')
        ) {
          handleLogoutUser();
        }
      } else {
        window.localStorage.setItem('lastTimeStamp', '');
      }
    };
    const handleTabClose = (event) => {
      window.localStorage.setItem('lastTimeStamp', moment());
    };

    window.addEventListener('load', handleLoad);
    window.addEventListener('unload', handleTabClose);

    const interval = setInterval(() => {
      setCountdown(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('unload', handleTabClose);

      clearInterval(interval);
      // window.removeEventListener("blur", onBlur);
    };
  }, []);

  React.useEffect(() => {
    activate();
  }, [window.location.pathname]);

  const ExcludeInactivityRoutes = [
    'sign-in',
    'sign-up',
    'verify-business',
    'mfa-setup',
    'verification-pin',
    'forgot-password',
    'authenticator-setup',
    'confirmed-business',
    'email-verification',
    'thank-you',
    'error-page'
  ];

  return (
    <React.Fragment>
      {renderRoutes}
      <div className="cs-toast">
        <ToastContainer
          theme="light"
          position="top-right"
          autoClose={3000}
          pauseOnFocusLoss={false}
          newestOnTop
          hideProgressBar={true}
          transition={Flip}
        />
      </div>
      {!!!ExcludeInactivityRoutes.find(
        (route) => window.location.pathname.includes(route) || window.location.pathname === '/'
      ) &&
        isLastActiveTab() &&
        countdown <= PROMPT_TIMER && (
          <InactivityLogoutModal
            remainingSeconds={countdown}
            onStay={handleStay}
            handleLogoutUser={handleLogoutUser}
          />
        )}
    </React.Fragment>
  );
}

export default App;
