import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import MFASetupCard from '../components/MFASetupCard';
import { MFA_TYPES } from '../../../constants/system';
import GoogleQRModal from '../components/GoogleQRModal';
import { TOAST_MESSAGE } from '../../../constants/message';
import PhoneInputModal from '../components/PhoneInputModal';
import SkeletonMfa from '../../components/skeleton/SkeletonMfa';
import MFAVerificationModal from '../../../components/Modals/MFAVerificationModal';

// API
import { generateMFAOTP, getUserDetails, resetMFA, useUserID } from '../../../slices/authSlice';

/* ============================== MFA SETUP ============================== */
const MFASetup = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userId = useUserID();

  const [showPhoneInputModal, setShowPhoneInputModal] = useState(false);
  const [showGooglQRModal, setShowGooglQRModal] = useState(false);
  const [showGooglePinModal, setShowGooglePinModal] = useState(false);
  const [showPhonePinModal, setShowPhonePinModal] = useState(false);

  const googleAlreadySetup = !!(
    user &&
    user.users_mfa_info &&
    user.users_mfa_info.mfa_google === true
  );

  const phoneAlreadySetup = !!(
    user &&
    user.users_mfa_info &&
    user.users_mfa_info.mfa_phone === true
  );

  const showToastMessage = (res, successToastMessage) => {
    if (res.code === 200) {
      toast.success(successToastMessage);
      dispatch(getUserDetails(`${userId}`));
    } else {
      toast.error(TOAST_MESSAGE.SOMETHING_WENT_WRONG);
    }
  };

  const handleAuthenticatorButtonClick = async () => {
    if (googleAlreadySetup) {
      const payload = { user_id: userId };
      const res = await dispatch(resetMFA({ mfaType: MFA_TYPES.AUTHENTICATOR, payload })).unwrap();
      showToastMessage(res, TOAST_MESSAGE.MFA_GOOGLE_RESET_SUCCESS);
    } else {
      setShowGooglQRModal(true);
    }
  };

  const handleMobileAuthButtonClick = async () => {
    if (phoneAlreadySetup) {
      const payload = { user_id: userId };
      const res = await dispatch(resetMFA({ mfaType: MFA_TYPES.PHONE, payload })).unwrap();
      showToastMessage(res, TOAST_MESSAGE.MFA_PHONE_RESET_SUCCESS);
    } else {
      setShowPhoneInputModal(true);
    }
  };

  const handleGetCodeClick = async () => {
    dispatch(generateMFAOTP({ mfaType: MFA_TYPES.PHONE, payload: { user_id: user && user.id } }));
    setShowPhoneInputModal(false);
    setShowPhonePinModal(true);
  };

  const handlePhoneMfaVerificationModalClose = () => {
    setShowPhonePinModal(false);
    dispatch(getUserDetails(`${user && user.id}`));
  };

  const handleGoogleMfaVerificationModalClose = () => {
    setShowGooglePinModal(false);
    dispatch(getUserDetails(`${user && user.id}`));
  };
  const [loading] = useOutletContext();
  return (
    <React.Fragment>
      <PhoneInputModal
        show={showPhoneInputModal}
        setShow={setShowPhoneInputModal}
        handleGetCodeClick={handleGetCodeClick}
      />
      <GoogleQRModal
        show={showGooglQRModal}
        setShow={setShowGooglQRModal}
        setShowGooglePinModal={setShowGooglePinModal}
      />
      <MFAVerificationModal
        modalState={showGooglePinModal}
        mfaType={MFA_TYPES.AUTHENTICATOR}
        handleModalClose={handleGoogleMfaVerificationModalClose}
        successToastMessage={TOAST_MESSAGE.MFA_GOOGLE_SUCCESS}
      />
      <MFAVerificationModal
        modalState={showPhonePinModal}
        mfaType={MFA_TYPES.PHONE}
        handleModalClose={handlePhoneMfaVerificationModalClose}
        successToastMessage={TOAST_MESSAGE.MFA_PHONE_SUCCESS}
      />
      <div className="cs-setting-page cs-mfa-setup-page">
        <div className="mfa-setup-page">
          <Form>
            <div className="mfa-cards">
              {loading ? (
                <React.Fragment>
                  <SkeletonMfa /> <SkeletonMfa />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MFASetupCard
                    icon={'mobile-outlined'}
                    title={'Mobile verification'}
                    description={'Send a code sent to your phone via SMS'}
                    buttonName={phoneAlreadySetup ? 'Reset' : 'Setup'}
                    isButtonPrimary={!phoneAlreadySetup}
                    key={'1'}
                    onButtonClick={handleMobileAuthButtonClick}
                  />
                  <MFASetupCard
                    icon={'qr-code-outlined'}
                    title={'Authenticator app'}
                    description={'Connect your authentication app'}
                    buttonName={googleAlreadySetup ? 'Reset' : 'Setup'}
                    isButtonPrimary={!googleAlreadySetup}
                    key={'2'}
                    onButtonClick={handleAuthenticatorButtonClick}
                  />
                </React.Fragment>
              )}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MFASetup;
