import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncToolTip from '../../components/CapsyncToolTip';

/* ============================== USER PROGRESS INDICATOR ============================== */
const UserProgressIndicator = ({ totalUser, noOfUserAccepted = 0, noOfUserDeclined = 0 }) => {
  const statusArray = new Array(totalUser).fill(null);

  statusArray.fill(true, 0, noOfUserAccepted);
  if (noOfUserDeclined > 0) statusArray.fill(false, noOfUserAccepted, totalUser);

  return (
    <div className="cs-progress-indicator">
      <div className="cs-success cs-indicator">
        <span>
          <CapsyncToolTip
            Child={'signature-timline-outlined'}
            placement={'top'}
            message={'Signature request has been sent'}
            type="icon"
            size="22"
          />
        </span>
        <span className="cs-indicator-line"></span>
      </div>

      {statusArray.map((status, index) => {
        const isSingleUser = totalUser === 1;
        return (
          <div
            className={`${status === true ? 'cs-success' : status === false ? 'cs-danger' : 'cs-neutral-50'} cs-indicator`}
            key={index}>
            <span>
              <CapsyncToolTip
                Child={'information-outlined'}
                placement={'top'}
                message={`${
                  status === true
                    ? isSingleUser
                      ? 'Signature added'
                      : `${index + 1}${index + 1 === 1 ? 'st' : 'nd'} Signature added`
                    : status === false
                      ? isSingleUser
                        ? 'Signature rejected'
                        : `${index + 1}${index + 1 === 1 ? 'st' : 'nd'} User rejected`
                      : isSingleUser
                        ? 'Waiting for signature'
                        : `Waiting for ${index + 1}${index + 1 === 1 ? 'st' : 'nd'} signature`
                }`}
                type="icon"
                size="22"
              />
            </span>
            <span className="cs-indicator-line"></span>
          </div>
        );
      })}

      <div
        className={`cs-indicator ${statusArray[statusArray.length - 1] === true ? 'cs-success' : statusArray[statusArray.length - 1] === false ? 'cs-danger' : 'cs-neutral-50'}`}>
        <span>
          <CapsyncToolTip
            Child={
              statusArray[statusArray.length - 1] === false
                ? 'declined-outlined'
                : 'verification-outlined'
            }
            placement={'top'}
            // message={'Waiting for review'}
            message={`${statusArray[statusArray.length - 1] === false ? 'Verification rejected' : statusArray[statusArray.length - 1] === true ? 'Verification completed' : 'Waiting for verification'}`}
            type="icon"
            size="22"
          />
        </span>
      </div>
    </div>
  );
};

// PROPS TYPE
UserProgressIndicator.propTypes = {
  totalUser: PropTypes.number.isRequired,
  noOfUserAccepted: PropTypes.number,
  noOfUserDeclined: PropTypes.number
};

export default UserProgressIndicator;
