import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-bootstrap';

// COMPONENT IMPORTS
import SkeletonTable from './SkeletonTable';

/* ============================== SKELETON DASHBOARD ============================== */
const SkeletonDashboard = () => {
  return (
    <div className="cs-section-top-bar">
      <div className="wm-skeleton-margin">
        <h1 className="cs-semi-bold-h1 cs-neutral-100">
          <Skeleton width={200} />
        </h1>
      </div>
      <div className="category-cards ">
        <Row>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Col key={index} md={4} lg={4}>
                <div className="category-card skeleton-cards">
                  <div className="card">
                    <div>
                      <Skeleton width={65} height={65} circle />
                    </div>
                    <div className="card-item">
                      <span className="cs-regular-h4 cs-neutral-90">
                        {' '}
                        <Skeleton width={100} />
                      </span>
                      <h3 className="cs-semi-bold-h2 cs-neutral-100">
                        <Skeleton width={60} />
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>

      <div className="skeleton-manage-container wm-skeleton-tabs-container for-des">
        <div className="skeleton-tabs-container">
          <h1 className="skeleton-tab-1">
            <Skeleton width={200} />
          </h1>
          <h1 className="skeleton-tab-2">
            <Skeleton width={200} />
          </h1>
        </div>
        <div>
          <Skeleton width={100} />
        </div>
      </div>

      <div className="skeleton-table">
        <SkeletonTable tableData={[1, 2, 3]} />
      </div>
    </div>
  );
};

export default SkeletonDashboard;
