import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import DashboardCompanyClient from './DashboardCompanyClient';
import DashboardIndividualClient from './DashboardIndividualClient';
import SkeletonDashboard from '../components/skeleton/SkeletonDashboard';

/* ============================== DASHBOARD ============================== */
const Dashboard = () => {
  const navigation = useNavigate();

  const countData = useSelector((state) => state.client.dashboardCount);
  const { user } = useSelector((state) => state.auth);

  const radioName = [
    { name: 'Individual', value: 'client' },
    { name: 'Companies', value: 'company' }
  ];

  const [tab, setTab] = useState('client');

  const handleViewAll = () => {
    if (tab === 'client') {
      navigation('/dashboard/individual');
    } else {
      navigation('/dashboard/company');
    }
  };

  const [loading] = useOutletContext();

  return (
    <section className="main-section">
      <div className="container-fluid">
        {loading ? (
          <SkeletonDashboard />
        ) : (
          <React.Fragment>
            <div className="cs-section-top-bar">
              <div className="cs-dashboard-title">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">
                  {user.first_time_login > 1 ? 'Welcome Back' : 'Welcome'}, {user?.display_name}
                </h1>
              </div>
            </div>
            <div className="cs-dashboard-cards category-cards">
              <Row>
                <Col md={4} lg={4}>
                  <div
                    className="category-card"
                    onClick={() => navigation('/dashboard/individual')}>
                    <div className="card">
                      <div className="card-icon">
                        <span className="cs-primary cs-icon">
                          <CapsyncIcon title="user-single-filled" size="40" />
                        </span>
                      </div>
                      <div className="card-item">
                        <span className="cs-regular-h4 cs-neutral-90">Individuals</span>
                        <h3 className="cs-semi-bold-h2 cs-neutral-100">{countData?.userCount}</h3>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="category-card" onClick={() => navigation('/dashboard/company')}>
                    <div className="card">
                      <div className="card-icon">
                        <span className="cs-primary cs-icon">
                          <CapsyncIcon title="company-filled" size="40" />
                        </span>
                      </div>
                      <div className="card-item">
                        <span className="cs-regular-h4 cs-neutral-90">Companies</span>
                        <h3 className="cs-semi-bold-h2 cs-neutral-100">
                          {countData?.companyCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="category-card">
                    <div className="card">
                      <div className="card-icon">
                        <span className="cs-primary cs-icon">
                          <CapsyncIcon title="user-multiple-filled" size="40" />
                        </span>
                      </div>
                      <div className="card-item">
                        <span className="cs-regular-h4 cs-neutral-90">Total Clients</span>
                        <h3 className="cs-semi-bold-h2 cs-neutral-100">{countData?.totalCount}</h3>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="cs-company-individual">
              <div className="cs-tabs-flex">
                <div className="cs-tabs">
                  {radioName.map((radioTab, id) => {
                    return (
                      <button
                        key={id}
                        type="button"
                        onClick={() => setTab(radioTab.value)}
                        className={`cs-stroke-default-tab cs-regular-h5 btn btn-primary${tab === radioTab.value ? ' cs-stroke-active' : ''}`}>
                        {radioTab.name}
                      </button>
                    );
                  })}
                </div>
                <div className="cs-tabs-note cs-regular-sub-heading-s cs-neutral-70">
                  * Recently viewed
                </div>
              </div>
            </div>
            <div className="cs-individual-table">
              {tab === 'client' ? (
                <DashboardIndividualClient tab={tab} />
              ) : (
                <DashboardCompanyClient tab={tab} />
              )}
              <div className="cs-view-all">
                <span
                  className="cs-regular-sub-heading-s cs-primary cursor-pointer"
                  onClick={handleViewAll}>
                  View all
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </section>
  );
};

export default Dashboard;
