import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';
import Searchbar from './Searchbar';

const paginationDropdownOptions = [
  {
    label: '10',
    value: 10
  },
  {
    label: '15',
    value: 15
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '25',
    value: 25
  },
  {
    label: '30',
    value: 30
  },
  {
    label: 'All',
    value: 'all'
  }
];

const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
  if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
    return rowsPerPage;
  } else {
    return lengthOfCompanies - rowStartIdx;
  }
};

/* ============================== CUSTOM TABLE ============================== */
const CustomTable = ({
  data = [],
  columns = [],
  isPaginate = false,
  totalRecords = 0,
  onPaginationValueChange,
  className = '',
  hasSearchBar = false,
  search = '',
  setSearch,
  type,
  setColumnDetails,
  fromIndividual,
  handleExportData,
  checkExport
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownOptions[0]);
  const [rowStartIdx, setRowStartIdx] = useState(0);

  useEffect(() => {
    if (fromIndividual) {
      setColumnDetails({
        rowsPerPage: rowsPerPage,
        rowStartIdx: rowStartIdx,
        setRowStartIdx: setRowStartIdx
      });
    }
  }, [rowsPerPage, rowStartIdx]);

  const onHandleChangeRowsPerPageValue = (option) => {
    setRowsPerPage(option);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: option.value
    });
  };

  const onHandleChangePage = (index) => {
    setRowStartIdx(index);
    onPaginationValueChange({
      page: Math.floor(index / rowsPerPage.value) + 1,
      limit: rowsPerPage.value
    });
  };

  const handleTableSearch = (searchText) => {
    setSearch(searchText);
    setRowStartIdx(0);
    onPaginationValueChange({
      page: 1,
      limit: rowsPerPage.value
    });
  };

  return (
    <React.Fragment>
      {hasSearchBar && (
        <Searchbar placeholder={'Search'} search={search} setSearch={handleTableSearch} />
      )}
      {data.length ? (
        <React.Fragment>
          <div
            className={`cs-table-widget ${type === 'ManageClientTeamCollabModel' ? 'cs-scroller' : ''} `}>
            <Table className={`cs-table ${className}`}>
              <thead className="cs-thead">
                <tr>
                  {columns.map((column, i) => (
                    <th key={i} width={column && column.width}>
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="cs-tbody">
                {data.map((row, i) => (
                  <tr
                    key={i}
                    className={
                      i === 0 ? (data.length >= 3 ? '' : 'single-row-data') : 'multiple-row-data'
                    }>
                    {columns.map((column, j) => {
                      return <td key={i + j}>{column.renderCell(row)}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {isPaginate && (
            <div className="cs-export-row">
              {fromIndividual && (
                <span
                  className={
                    checkExport && checkExport
                      ? 'cs-regular-body-text-m export-btn cs-neutral-80 cs-disabled'
                      : 'cs-regular-body-text-m export-btn cs-primary cursor-pointer'
                  }
                  onClick={handleExportData}>
                  <CapsyncIcon title="download-filled" size="18" /> Export Data
                </span>
              )}
              <div className="cs-custom-pagination">
                <div className="cs-common-add-dropdown">
                  <span className="cs-light-body-text-m cs-neutral-80">Rows per page:</span>
                  <Dropdown>
                    <Dropdown.Toggle className="cs-light-body-text-m cs-neutral-80">
                      {rowsPerPage.label} {/* Render the label property */}
                      <CapsyncIcon title="chevron-down-outlined" size="12" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {paginationDropdownOptions.map((option) => {
                        const isSelected = option.label === rowsPerPage.label;
                        return (
                          <Dropdown.Item
                            key={option.value}
                            className={isSelected ? 'cs-pagination-select' : ''}
                            onClick={() => onHandleChangeRowsPerPageValue(option)}
                            value={option.value}>
                            {option.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="cs-light-body-text-m cs-neutral-80">
                  <p>
                    {rowStartIdx + 1}-
                    {rowStartIdx +
                      currentNumberOfRows(rowStartIdx, rowsPerPage.value, totalRecords)}{' '}
                    of {totalRecords}
                  </p>
                </div>
                <div className="cs-pagination-arrow">
                  <Button
                    onClick={() => onHandleChangePage(0)}
                    className="cs-neutral-50"
                    disabled={rowStartIdx === 0}>
                    <CapsyncIcon title="chevron-left-skip-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() => onHandleChangePage(rowStartIdx - rowsPerPage.value)}
                    disabled={rowStartIdx === 0}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-left-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() => onHandleChangePage(rowStartIdx + rowsPerPage.value)}
                    disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-right-outlined" size="14" />
                  </Button>
                  <Button
                    onClick={() =>
                      onHandleChangePage(
                        Math.floor((totalRecords - 1) / rowsPerPage.value) * rowsPerPage.value
                      )
                    }
                    disabled={!(rowStartIdx + rowsPerPage.value < totalRecords)}
                    className="cs-neutral-50">
                    <CapsyncIcon title="chevron-right-skip-outlined" size="14" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
          No data found
        </span>
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
CustomTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isPaginate: PropTypes.bool,
  totalRecords: PropTypes.number,
  onPaginationValueChange: PropTypes.func,
  className: PropTypes.string,
  hasSearchBar: PropTypes.bool,
  search: PropTypes.string,
  type: PropTypes.string,
  setSearch: PropTypes.func,
  setColumnDetails: PropTypes.func,
  handleExportData: PropTypes.func,
  fromIndividual: PropTypes.bool,
  checkExport: PropTypes.bool
};

export default CustomTable;
