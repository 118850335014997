import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormLabel } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== SEARCH BOX ============================== */
const SearchBox = ({ label, name = 'search-box', placeholder, value, onChange }) => {
  return (
    <div className="cs-search-bar">
      <Form.Group className="cs-form-group">
        {label && <FormLabel className="cs-neutral-60 cs-regular-body-text-s">{label}</FormLabel>}
        <Form.Control
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        <span className="input-field-icon cursor-pointer cs-neutral-80">
          <CapsyncIcon title="search-outlined" size="18" />
        </span>
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
SearchBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

export default SearchBox;
