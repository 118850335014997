import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';
import config from '../constants/config';

// COLLABORATION PERMISSION LIST API CALL
export const allPermissionList = createAsyncThunk('collaboration/allPermissionList', async () => {
  const res = await axios.get(`${CONFIG.BASE_URL}role`);
  return res;
});

// ADD COLLABORATION USER
export const addCollabUser = createAsyncThunk(
  'collaboration/add_user',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}collaboration/add_user`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// CHANGE PERMISSION OF USER
export const changeUserPermission = createAsyncThunk(
  'collaboration/permission',
  async ({ userId, body }, { rejectWithValue }) => {
    const res = await axios.put(`${CONFIG.BASE_URL}collaboration/permission/${userId}`, body);
    return res;
  }
);

// COLLABORATION PERMISSION LIST API CALL
export const allCollaborationUsersList = createAsyncThunk(
  'collaboration/allCollaborationUsersList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}collaboration`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// LIST ALL USERS BY COLLABORATION ID API CALL
export const getClientsByCollabUser = createAsyncThunk(
  'collaboration/getClientsByCollabUser',
  async (userId) => {
    try {
      const res = await axios.get(`${CONFIG.BASE_URL}collaboration/client/${userId}`);
      return res.data;
    } catch (e) {
      console.log('e', e);
    }
  }
);

// COLLABORATION PERMISSION LIST API CALL
export const allUsersList = createAsyncThunk('collaboration/allUsersList', async (params) => {
  try {
    const res = await axios.get(`${CONFIG.BASE_URL}collaboration`, { params });
    return res.data;
  } catch (e) {
    console.log('e', e);
  }
});

// DELETE COLLABORATION USER
export const deleteCollabUser = createAsyncThunk('collaboration/deleteUser', async (params) => {
  try {
    const res = await axios.delete(`${CONFIG.BASE_URL}collaboration`, { params });
    return res;
  } catch (e) {
    console.log('e', e);
  }
});

// MOVE CLIENT FROM USER TO ANOTHER USER
export const moveCollabUserClients = createAsyncThunk('collaboration/transfer', async (data) => {
  try {
    const res = await axios.post(`${CONFIG.BASE_URL}collaboration/transfer`, data);
    return res.data;
  } catch (e) {
    console.log('e', e);
  }
});

// REMOVE CLIENT FROM USER
export const removeClientFromUser = createAsyncThunk('collaboration/deleteUser', async (data) => {
  try {
    const res = await axios.delete(`${CONFIG.BASE_URL}collaboration/remove/access`, { data });
    return res.data;
  } catch (e) {
    console.log('e', e);
  }
});

// CLEAR FORM AFTER SUBMIT
export const resetError = createAction('collaboration/resetForm');

// INITIAL STATE
const initialState = {
  permissionOptionList: [],
  message: '',
  status: null,
  usersRecord: {},
  clientsAccess: null
};

/* ============================== COLLABORATION SLICE ============================== */
export const collaborationSlice = createSlice({
  name: 'collaboration',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(allPermissionList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.permissionOptionList = data;
      })
      .addCase(allCollaborationUsersList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.usersRecord = data;
      })
      .addCase(addCollabUser.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(getClientsByCollabUser.fulfilled, (state, action) => {
        state.clientsAccess = action?.payload;
        state.status = config.STATUS.SUCCESS;
      })
      .addCase(changeUserPermission.fulfilled, (state, { payload }) => {
        const { roleId, userId } = payload;
        state.usersRecord = {
          ...state.usersRecord,
          records: state.usersRecord.records.map((record) => {
            if (record.id === userId) {
              return {
                ...record,
                role_id: parseInt(roleId)
              };
            }
            return record;
          })
        };
      })
      .addCase(resetError, (state) => ({ ...state, message: '', status: null }));
  }
});

const { reducer } = collaborationSlice;
export default reducer;

const selectClientAccess = (state) => state.collaboration.clientsAccess;
export const useClientAccess = () => {
  const clientsAccess = useSelector(selectClientAccess);
  return useMemo(() => clientsAccess, [clientsAccess]);
};
