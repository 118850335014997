import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== COMMON SPINNER ============================== */
const CommonSpinner = ({ classParent = '', size }) => {
  return (
    <span className={`cs-common-spinner ${classParent}`}>
      <CapsyncIcon title="loading-outlined" size={size} />
    </span>
  );
};

// PROPS TYPE
CommonSpinner.propTypes = {
  classParent: PropTypes.str,
  size: PropTypes.str
};

export default CommonSpinner;
