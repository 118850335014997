import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import CustomAccordionTable from '../../components/CustomAccordionTable';
import ManageIndividualClientModel from './components/ManageIndividualClientModal';

// API
import { dashboardCompaniesList } from '../../slices/clientSlice';

/* ============================== DASHBOARD COMPANY ============================== */
const DashboardCompanyClient = ({ tab }) => {
  const dispatch = useDispatch();
  const { companiesRecord } = useSelector((state) => state.client);

  const [clientAdded, setClientAdded] = useState(false);
  const [checkAccordion, setCheckAccordion] = useState({ isOpen: false, accordionData: null });
  const [activeRow, setActiveRow] = useState(-1);
  const [manageClientModelId, setManageClientModelId] = useState(-1);
  const [isModelOpen, setIsModelOpen] = useState({
    manageClient: false,
    addClient: false
  });

  useEffect(() => {
    getAllCollaborationUsersList({
      type: tab
    });
    return () => {
      setClientAdded(false);
    };
  }, [clientAdded]);

  const getAllCollaborationUsersList = (params) => {
    dispatch(dashboardCompaniesList(params));
  };

  const COLUMNS = [
    {
      label: 'Company Name',
      renderCell: (item) => item?.firm_company?.company_name
    },
    {
      label: 'Members',
      renderCell: (item) => item.firm_company.member_count
    },
    {
      label: 'PFS Submitted',
      renderCell: (item) => findPFS(item)
    },

    {
      label: 'User Assigned',
      renderCell: (item) => (
        <span
          className="cursor-pointer cs-primary"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModel('manageClient');
            setManageClientModelId(item?.firm_company?.id);
          }}>
          View & Manage
        </span>
      )
    },
    {
      label: '',
      renderCell: (item) => (
        <div className="text-end">
          <span className="cs-neutral-80 cursor-pointer">
            {checkAccordion?.isOpen &&
            checkAccordion?.accordionData?.firm_company.id === item.firm_company.id ? (
              <CapsyncIcon title="chevron-up-outlined" size="18" />
            ) : (
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            )}
          </span>
        </div>
      )
    }
  ];

  const findPFS = (item) => {
    const pfs = item?.firm_company?.company_clients;
    const count = pfs?.filter((client) => client.pfs_access_status === '2').length;
    return `${count > 0 ? count : 0}/${item.firm_company.member_count}`;
  };

  const handleOpenModel = (key, e) => {
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
  };

  const handleCloseModel = (key) => {
    if (key === 'manageClient') {
      setManageClientModelId(-1);
    }

    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };
  const handleAddMoreClient = () => {
    setIsModelOpen({
      ...isModelOpen,
      manageClient: false,
      addClient: true
    });
  };

  return (
    <React.Fragment>
      <div className="companies-table">
        <CustomAccordionTable
          columns={COLUMNS}
          data={companiesRecord ? companiesRecord : []}
          isPaginate={false}
          totalRecords={(companiesRecord && companiesRecord.length) || 0}
          fromDashboard={true}
          setCheckAccordion={setCheckAccordion}
          setActiveRow={setActiveRow}
          activeRow={activeRow}
        />
      </div>
      {isModelOpen.manageClient && (
        <ManageIndividualClientModel
          handleCloseModel={() => handleCloseModel('manageClient')}
          userId={manageClientModelId}
          handleAddMoreClient={handleAddMoreClient}
          fromTable={'companyClient'}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
DashboardCompanyClient.propTypes = {
  tab: PropTypes.string
};

export default DashboardCompanyClient;
