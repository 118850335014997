import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CustomTable from '../../../components/CustomTable';

/* ============================== ALREADY EXIST CLIENT MODAL ============================== */
const AlreadyExistingClientsModal = (props) => {
  const { handleCloseModel, show } = props;

  const { alreadyPresentClients } = useSelector((state) => state.client);

  const COLUMNS = [
    {
      label: 'First name',
      renderCell: (item) => item.first_name
    },
    { label: 'Email', renderCell: (item) => item.email }
  ];

  return (
    <div>
      <Modal
        title={'Validation'}
        show={show}
        handleClose={() => handleCloseModel(false)}
        className="cs-md-modal cs-new-user-modal"
        body={
          <div className="cs-text-center logout-modal">
            <span className="icon cs-icon">
              <CapsyncIcon title="error-validation" size="100" />
            </span>
            <div className="cs-regular-body-text-m cs-danger">
              User already exists in your firm, please contact your firm admin to get access to the
              users profile.
            </div>
            <CustomTable columns={COLUMNS} data={alreadyPresentClients} />
          </div>
        }
      />
    </div>
  );
};

// PROPS TYPE
AlreadyExistingClientsModal.propTypes = {
  handleCloseModel: PropTypes.func,
  show: PropTypes.bool
};

export default AlreadyExistingClientsModal;
