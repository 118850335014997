import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { resetForm } from '../../../slices/authSlice';

import Logo from '../../../assets/images/brand/capsync-logo.svg';
import AuthBanner from '../../AuthBanner';
import { AUTH_CONTENT } from '../../../constants/content';

const Confirmed = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);

  const handleContinue = () => {
    dispatch(resetForm());
    if (status === null) {
      navigation('/');
    }
  };

  return (
    <section className="auth-section thankyou-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">{AUTH_CONTENT.BUSINESS_INFO_CONFIRMED_TITLE}</h1>
                </div>
                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                  {AUTH_CONTENT.BUSINESS_INFO_CONFIRMED_DETAIL}
                </p>
              </div>
              <div className="cs-auth-form">
                <Row>
                  <Col>
                    <div className="auth-footer">
                      <Button
                        type="submit"
                        className="cs-btn-primary lg-btn cs-regular-h5"
                        onClick={handleContinue}>
                        {' '}
                        Continue{' '}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Confirmed;
