import React from 'react';

// COMPONENT IMPORTS
import FallBack from '../components/FallBack';
import AuthRoutes from './AuthRoutes';
import DashboardRoutes from './DashboardRoutes';

const PageNotFoundRoute = {
  path: '*',
  element: <FallBack />
};

/* ============================== APP ROUTES ============================== */
const AppRoutes = [
  {
    path: '/',
    children: [AuthRoutes, DashboardRoutes, PageNotFoundRoute]
  }
];

export default AppRoutes;
