import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import SkeletonSettingsTable from './SkeletonSettingsTable';

/* ============================== SKELETON AUDIT LOGS ============================== */
const SkeletonAuditlog = () => {
  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role;
  return (
    <React.Fragment>
      <div
        className={`skeleton-audit-container ${role === 1 ? 'skeleton-manage-audit-1' : 'skeleton-manage-audit-2'}`}>
        {role === 'Individual' && (
          <div className="for-des">
            {' '}
            <div className="skeleton-tabs-container">
              <h1 className="skeleton-tab-1">
                <Skeleton width={200} />
              </h1>
              <h1 className="skeleton-tab-2">
                <Skeleton width={200} />
              </h1>
            </div>
          </div>
        )}
        <div className="skeleton-audit-log-search-tab">
          {role === 'Individual' && (
            <div className="for-mob">
              {' '}
              <div className="skeleton-tabs-container">
                <h1 className="skeleton-tab-1">
                  <Skeleton width={130} />
                </h1>
                <h1 className="skeleton-tab-2">
                  <Skeleton width={130} />
                </h1>
              </div>
            </div>
          )}
          <div className="for-mob">
            <Skeleton width="100" height={30} />
          </div>
        </div>
        <div className="for-des">
          <Skeleton width={200} height={30} />
        </div>
      </div>
      <SkeletonSettingsTable tableData={[1, 2, 3, 4, 5, 6]} />
    </React.Fragment>
  );
};

export default SkeletonAuditlog;
