import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';
import config from '../constants/config';
import axiosFormData from '../config/apiFormData';

// DASHBOARD CLIENT LIST API CALL
export const dashboardClientsList = createAsyncThunk(
  'dashboardClientsList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}recently_viewed`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// DASHBOARD COMPANY LIST API CALL
export const dashboardCompaniesList = createAsyncThunk(
  'dashboardCompaniesList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}recently_viewed`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// CLIENT LIST API CALL
export const allClientsList = createAsyncThunk(
  'client/allClientsList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}client`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// COMPLETE CLIENT LIST API CALL
export const completeClientsList = createAsyncThunk(
  'client/completeClientsList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}client`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// COMPANY LIST API CALL
export const getCompaniesList = createAsyncThunk(
  'getcompany',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}company`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// COMPLETE COMPANY LIST API CALL
export const getCompleteCompaniesList = createAsyncThunk(
  'getcompanyList',
  async (params, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}company`, { params });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// ADD INDIVIDUAL CLIENT
export const addIndividualClient = createAsyncThunk(
  'client/invite/single',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}client/invite/single`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// REASSIGN INDIVIDUAL CLIENT
export const reAssignClient = createAsyncThunk(
  'client/re_assign',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}client/re_assign`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// ADD COMPANY CLIENT
export const addCompany = createAsyncThunk('postcompany', async (data, { rejectWithValue }) => {
  const res = await axios.post(`${CONFIG.BASE_URL}company`, data);
  if (res.code === 200) {
    return res;
  } else {
    return rejectWithValue(res.errors || res.message || '');
  }
});

// ADD BULK CLIENT
export const addBulkClient = createAsyncThunk(
  'client/invite/bulk',
  async (data, { rejectWithValue }) => {
    const res = await axiosFormData.post(`${CONFIG.BASE_URL}client/invite/bulk`, data);
    if (res.status) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// ADD CLIENT ACCESS
export const addClientAccess = createAsyncThunk(
  'client/access',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}client/access`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// DELETE INDIVIDUAL CLIENT
export const deleteClient = createAsyncThunk('client', async (data) => {
  try {
    const res = await axios.delete(`${CONFIG.BASE_URL}client`, { data });
    return res;
  } catch (e) {
    console.log('e', e);
  }
});

// DELETE COMPANY CLIENT
export const deleteCompany = createAsyncThunk('deleteCompany', async (data) => {
  try {
    const res = await axios.delete(`${CONFIG.BASE_URL}company`, { data });
    return res;
  } catch (e) {
    console.log('e', e);
  }
});

// REMOVE PROFESSIONAL ACCESS FROM INDIVIDUAL CLIENT
export const removeProfessionalAccess = createAsyncThunk(
  'removeProfessionalAccess',
  async (data) => {
    try {
      const res = await axios.delete(`${CONFIG.BASE_URL}client/remove/access`, { data });
      return res;
    } catch (e) {
      console.log('e', e);
    }
  }
);

// UPDATE COMPANY
export const UpdateCompany = createAsyncThunk(
  'updatecompany',
  async (data, { rejectWithValue }) => {
    const res = await axios.put(`${CONFIG.BASE_URL}company/${data.id}`, {
      company_name: data.company_name
    });
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// LIST OF ALL PROFESSIONAL USERS
export const getAllProfessionalUsers = createAsyncThunk(
  'client/getAllProfessionalUsers',
  async (userId) => {
    try {
      const res = await axios.get(`${CONFIG.BASE_URL}client/access?user_id=${userId}`);
      return res.data;
    } catch (e) {
      console.log('e', e);
    }
  }
);

// ADD RECENTLY VIEW INDIVIDUAL
export const addRecentlyViewedIndividual = createAsyncThunk(
  'recently_viewed/client',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}recently_viewed/client`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// ADD RECENTLY VIEW INDIVIDUAL
export const addRecentlyViewedCompany = createAsyncThunk(
  'recently_viewed/company',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}recently_viewed/company`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// CLEAR FORM AFTER SUBMIT
export const resetError = createAction('client/resetForm');

// REASSIGN CLIENT FROM USER TO ANOTHER USER
export const reassignClients = createAsyncThunk('client/reassign', async (payload) => {
  try {
    const res = await axios.post(`${CONFIG.BASE_URL}client/re_assign`, payload);
    return res;
  } catch (e) {
    console.log('e', e);
  }
});

// LIST OF Professional Users IN VIEW AND MANAGE
export const getCompanyAccess = createAsyncThunk('client/getCompanyAccess', async (userId) => {
  try {
    const res = await axios.get(`company/${userId}`);
    return res.data;
  } catch (e) {
    console.log('e', e);
  }
});

// ADD CLIENT ACCESS TO COMPANY TABLE in company view and manage.
export const assignCompanyAccess = createAsyncThunk(
  'company/access',
  async (data, { rejectWithValue }) => {
    const res = await axios.post('company/assign', data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// REMOVE PROFESSIONAL ACCESS FROM Company CLIENT
export const removeAccess = createAsyncThunk('removeAccess', async (data) => {
  try {
    const res = await axios.post('company/remove_access', data);
    return res;
  } catch (e) {
    console.log('e', e);
  }
});

// INITIAL STATE
const initialState = {
  message: '',
  status: null,
  clientsRecord: [],
  companiesRecord: [],
  alreadyPresentClients: []
};

/* ============================== CLIENT SLICE ============================== */
export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clearMessage(state) {
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardClientsList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.dashboardCount = data.dashboardCount;
        state.clientsRecord = data.records;
      })
      .addCase(dashboardCompaniesList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.dashboardCount = data.dashboardCount;
        state.companiesRecord = data.records;
      })
      .addCase(allClientsList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.clientsRecord = data;
      })
      .addCase(getCompaniesList.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.companiesRecord = data;
      })
      .addCase(getCompaniesList.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(resetError, (state) => ({ ...state, message: '', status: null }))

      .addCase(addIndividualClient.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(addIndividualClient.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(deleteClient.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(addCompany.fulfilled, (state, { payload }) => {
        const { message, data } = payload;
        state.status = config.STATUS.SUCCESS;
        state.alreadyPresentClients = data.already_present_clients;
        state.message = message;
      })
      .addCase(addCompany.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(deleteCompany.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(deleteCompany.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(addBulkClient.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(addBulkClient.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(UpdateCompany.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(UpdateCompany.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(getAllProfessionalUsers.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(getAllProfessionalUsers.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(removeProfessionalAccess.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(removeProfessionalAccess.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(reAssignClient.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(reAssignClient.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(addClientAccess.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(addClientAccess.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(assignCompanyAccess.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(assignCompanyAccess.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(getCompanyAccess.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(getCompanyAccess.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      })
      .addCase(removeAccess.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(removeAccess.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      });
  }
});

export const { clearMessage } = clientSlice.actions;
const { reducer } = clientSlice;
export default reducer;
