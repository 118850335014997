import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import Logo from '../assets/images/brand/capsync-logo.svg';
import GoogleQRCode from '../components/GoogleQRCode';

// HOOKS IMPORTS
import useAuthRedirect from '../hooks/useAuthRedirect';
import useRemoveServerError from '../hooks/useRemoveServerError';

// API
import { googleMFASetup } from '../slices/authSlice';

/* ============================== GOOGLE OR SETUP ============================== */
const GoogleSetupQR = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useRemoveServerError();
  useAuthRedirect();

  const handleNextClick = () => {
    dispatch(googleMFASetup({ user_id: user && user.id }));
    navigation('/verification-pin');
  };

  return (
    <section className="auth-section thankyou-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Authenticator app verification</h1>
                </div>
                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                  Scan the below QR code with your authenticator app.
                </p>
              </div>
              <div className="cs-auth-form">
                <Row>
                  <Col>
                    <div className="auth-footer">
                      <GoogleQRCode />
                      <Button
                        type="submit"
                        className="cs-btn-primary lg-btn cs-regular-h5"
                        onClick={handleNextClick}>
                        Next
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GoogleSetupQR;
