import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';

// Pending Signature LIST API CALL
export const getPendingSignatureList = createAsyncThunk(
  'getPendingSignatureList',
  async (params) => {
    const res = await axios.get('request/signature_list', { params });
    if (res.code === 200) {
      return res;
    } else {
      return res;
    }
  }
);

// COMPLETED Signature LIST API CALL
export const getCompletedSignatureList = createAsyncThunk(
  'getCompletedSignatureList',
  async (params) => {
    const res = await axios.get('request/signature_list', { params });
    if (res.code === 200) {
      return res;
    } else {
      return res;
    }
  }
);
// Signature Reminder
export const signatureReminder = createAsyncThunk('signatureReminder', async (params) => {
  const res = await axios.post('sign/reminder', params);
  if (res.code === 200) {
    return res;
  } else {
    return res;
  }
});

// Cancel Signature
export const cancelSignature = createAsyncThunk('cancelSignature', async (params) => {
  const res = await axios.post('sign/cancel', params);
  if (res.code === 200) {
    return res;
  } else {
    return res;
  }
});

// Share Signature
export const shareSignature = createAsyncThunk('shareSignature', async (body) => {
  const res = await axios.post('sign/share', body);
  if (res.code === 200) {
    return res;
  } else {
    return res;
  }
});

// INITIAL STATE
const initialState = {
  message: '',
  status: null,
  requestRecord: {},
  requestPagination: {}
};

/* ============================== REQUEST SLICE ============================== */
export const requestSlice = createSlice({
  name: 'request',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPendingSignatureList.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.requestRecord = data.records;
      state.requestPagination = data.pagination;
    });
  }
});

const { reducer } = requestSlice;
export default reducer;
