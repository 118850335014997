import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import AuthHead from './AuthHead';
import AuthBanner from './AuthBanner';
import CONFIG from '../constants/config';

// HOOKS IMPORTS
import useRemoveServerError from '../hooks/useRemoveServerError';

// API
import { resendEmailVerification, resetForm } from '../slices/authSlice';

/* ============================== EMAIL VERIFICATION ============================== */
const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const {
    user: { email },
    message,
    status
  } = useSelector((state) => state.auth);

  useRemoveServerError();

  const handleResendEmail = () => {
    dispatch(resendEmailVerification({ email }));
    dispatch(resetForm());
  };

  const handleClick = () => {
    navigation('/');
  };

  return (
    <section className="auth-section email-verification-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section verify-email-address">
              <AuthHead
                title={'Verify your email address'}
                description={
                  'Check your inbox for a verification email. Click the link to activate your CapSync account'
                }
              />
              <div className="cs-auth-form">
                <Row>
                  <Col>
                    <div className="auth-footer">
                      <h3 className="cs-medium-sub-heading-s cs-neutral-100">
                        Didn't receive an email yet?
                      </h3>
                      <Button
                        type="submit"
                        className="cs-btn-primary lg-btn cs-regular-h5"
                        onClick={handleResendEmail}>
                        Resend email
                      </Button>
                      <span
                        className={`cs-light-body-text-m cs-${status === CONFIG.STATUS.ERROR ? 'danger' : 'success'}`}>
                        {message}
                      </span>
                      <span className="back-to-sign-in">
                        <Button
                          className="cs-btn-tertiary lg-btn cs-regular-h5"
                          onClick={handleClick}>
                          Back to sign in
                        </Button>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EmailVerification;
