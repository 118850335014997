import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import MFAOption from '../../../components/MFASetup/MFAOptions';
import { MFA_OPTIONS_PFS_PASSWORD } from '../../../constants/system';

/* ============================== MFA OPTION MODAL ============================== */
const MfaOptionModal = (props) => {
  const {
    title,
    MFA_OPTION_LIST = MFA_OPTIONS_PFS_PASSWORD,
    callback,
    selectedOption,
    modalState,
    subHeader,
    handleOnSave,
    handleOnCancel
  } = props;

  const { user } = useSelector((state) => state.auth);

  const optionList = MFA_OPTION_LIST?.filter((option) => {
    if (option.id === 'authenticator' && user.users_mfa_info.mfa_google) {
      return true;
    } else if (option.id === 'mobile' && user.users_mfa_info.mfa_phone) {
      return true;
    } else if (option.id === 'email') {
      return true;
    }
    return false;
  });

  return (
    <Modal
      title={title}
      show={modalState}
      handleClose={handleOnCancel}
      body={
        <div className="auth-right">
          <div className="formArea">
            <div className="text-center">
              <p className="cs-neutral-80 cs-regular-h5">{subHeader}</p>
            </div>
            <div>
              {optionList?.map((option, index) => (
                <MFAOption
                  key={index}
                  {...option}
                  callback={callback}
                  selectedType={selectedOption}
                  setSelectedType={callback}
                />
              ))}
            </div>
          </div>
        </div>
      }
      isCloseButton={false}
      disabled={!selectedOption}
      className="cs-md-modal cs-modal-padding-null"
      saveButtonLabel={'Next'}
      cancelButtonLabel={'Close'}
      handleOnSave={handleOnSave}
      handleOnCancel={handleOnCancel}
    />
  );
};

// PROPS TYPE
MfaOptionModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  MFA_OPTION_LIST: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  subHeader: PropTypes.string
};

export default MfaOptionModal;
