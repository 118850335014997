import React from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== PASSWORD REQUIREMENT ============================== */
const PasswordRequirement = ({ password }) => {
  const requirements = [
    { text: 'No spaces allowed', check: /^\S*$/ },
    { text: 'Min 8 characters', check: /^.{8,}$/ },
    { text: 'Max 20 characters', check: /^.{1,20}$/ },
    { text: 'Use lower case letters', check: /[a-z]/ },
    { text: 'Use upper case letters', check: /[A-Z]/ },
    { text: 'Use a number', check: /\d/ },
    { text: 'Use a symbol', check: /[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/ }
  ];
  const isValid = (requirement) => requirement.check.test(password);

  return (
    <ul className="password-requirements">
      {requirements.map((requirement, index) => (
        <li
          key={index}
          className={`cs-light-body-text-s ${isValid(requirement) ? 'cs-success' : 'cs-danger'}`}>
          <span className={isValid(requirement) ? 'cs-success' : 'cs-danger'}>
            <CapsyncIcon
              title={isValid(requirement) ? 'check-outlined' : 'close-outlined'}
              size="14"
            />
          </span>
          {requirement.text}
        </li>
      ))}
    </ul>
  );
};

// PROPS TYPE
PasswordRequirement.propTypes = {
  password: PropTypes.string.isRequired
};

export default PasswordRequirement;
