import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../Modal';
import Avatar from './Avatar';
import CapsyncIcon from '../CapsyncIcon';
import { CropAvatar } from './CropAvatar';
import FileUpload from '../FormFields/FileUpload';
import { AVATAR_LIST } from '../../constants/config';

// API
import {
  getUserDetails,
  setSavedAvatar,
  userChangeAvatar,
  userChangeProfileImage,
  useUserID
} from '../../slices/authSlice';

const defaultAllowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
const defaultMaxFileSize = 5 * 1024 * 1024; // 5MB

const validateFile = (file) => {
  if (file.size > defaultMaxFileSize) {
    return { status: false, message: 'This file exceeds maximum file size limit (5MB)' };
  }

  if (!defaultAllowedTypes.includes(file.type)) {
    return { status: false, message: 'Format not supported' };
  }

  return { status: true, message: '' };
};

/* ============================== UPDATE AVATAR ============================== */
const UpdateAvatar = (props) => {
  const {
    setIsAvatarModalOpen,
    primaryLogoName,
    smallLogoName,
    handleAvatarSelected,
    selectedAvatar,
    setLogoType,
    setSmallLogo,
    setSmallLogoPreview,
    setDisableSmall,
    smallLogo,
    inputRef,
    setDisablePrimary,
    setPrimaryLogo,
    logoImageType,
    setPrimaryLogoPreview,
    uploadType,
    logoFile,
    setSelectedAvatar,
    selectedAvatarFromDb
  } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const userId = useUserID();

  const cropImgRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isCropImageVisible, setIsCropImageVisible] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [initials, setInitials] = useState('');

  const trimmedSelectorAvatar =
    typeof selectedAvatar === 'string'
      ? selectedAvatar &&
        selectedAvatar?.replace(
          /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
          ''
        )
      : '';

  const trimmedSelectedAvatarFromDb =
    typeof selectedAvatarFromDb === 'string'
      ? selectedAvatarFromDb &&
        selectedAvatarFromDb?.replace(
          /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
          ''
        )
      : '';

  const onHandleFileUpload = (files) => {
    setErrorMessage('');
    const isValidFile = validateFile(files[0]);
    if (!isValidFile.status) {
      setErrorMessage(isValidFile.message);
      return false;
    }
    setUploadedImage(URL.createObjectURL(files[0]));
    setUploadedImageName(files[0].name);
    setIsCropImageVisible(true);
  };

  const logoImageConfig = {
    primary: 'Upload Primary Logo',
    small: 'Upload Small Logo',
    default: 'Edit Profile Picture'
  };

  useEffect(() => {
    const name = user.display_name;
    if (name) {
      const nameArray = name.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      setInitials(initials.toUpperCase());
    }
  }, [user]);

  const bodyElement = () => {
    if (isCropImageVisible || uploadType == 'customBranding') {
      return (
        <CropAvatar
          img={uploadedImage ? uploadedImage : logoImageType == 'small' ? smallLogo : logoFile}
          imgRef={cropImgRef}
          imgType={logoImageType ? logoImageType : 'Avatar'}
        />
      );
    }

    return (
      <React.Fragment>
        <FileUpload
          onHandleFileUpload={onHandleFileUpload}
          multiple={false}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
        <p className="cs-regular-sub-heading-xs cs-neutral-60">
          Supports PNG, JPG and JPEG format, allowing images up to 5 MB in size.
        </p>
        <div className="cs-avatar-container">
          <h5 className="cs-regular-body-text-m cs-neutral-100">Select avatar</h5>
          <div className="cs-avatar-row">
            <div
              className={`profile ${'avatar_6.png' === selectedAvatar || ('/users/avatar/avatar_6.png' === trimmedSelectedAvatarFromDb && selectedAvatar === null) || (selectedAvatar === null && trimmedSelectedAvatarFromDb === '') ? 'selected-avatar' : ''}`}
              onClick={() => handleAvatarSelected('avatar_6.png')}>
              <div className="cs-avatar cs-avatar-text cs-avatar-xs">
                <span>{initials}</span>
              </div>
              <span className="cs-primary">
                <CapsyncIcon title="success-filled" size="18" />
              </span>
            </div>

            {AVATAR_LIST.map((data, index) => (
              <div
                className={`profile ${data.replace(/(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/, '') === trimmedSelectorAvatar || (data.replace(/(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/, '') === trimmedSelectedAvatarFromDb && selectedAvatar === null) ? 'selected-avatar' : ''}`}
                onClick={() => handleAvatarSelected(data)}
                key={index}>
                {!data && (
                  <div className="cs-avatar cs-avatar-xs">
                    <div className="profile-loader cs-primary">
                      <span className="cs-common-spinner">
                        <CapsyncIcon title="loading-outlined" size="22" />
                      </span>
                    </div>
                  </div>
                )}
                <Avatar isEditable={false} src={data} className="cs-avatar-xs cs-avatar-img" />
                <span className="cs-primary">
                  <CapsyncIcon title="success-filled" size="18" />
                </span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handleSaveAvatar = async (acceptedFile) => {
    const cropper = cropImgRef.current;
    const canvas = cropper?.getCanvas();
    const imageURL = canvas?.toDataURL();
    if (imageURL) {
      dispatch(setSavedAvatar(imageURL));
      dispatch(
        userChangeProfileImage({
          user_id: user.id,
          image_data: imageURL,
          image_name: uploadedImageName
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            dispatch(getUserDetails(`${userId}`));
            toast.success('Your profile image has been updated successfully.');
            setSelectedAvatar(null);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else {
      const relativeAvatarPath =
        selectedAvatar === 'avatar_6.png'
          ? 'users/avatar/avatar_6.png'
          : selectedAvatar.replace(
              /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com\/|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com\/)/,
              ''
            );
      // dispatch(setSavedAvatar(selectedAvatar));
      dispatch(
        userChangeAvatar({
          user_id: user.id,
          avatar_name: relativeAvatarPath
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            dispatch(getUserDetails(`${userId}`));
            toast.success('Your profile image has been updated successfully.');
            setSelectedAvatar(null);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    setIsAvatarModalOpen(false);
  };

  const handleLogoUpload = async () => {
    const cropper = cropImgRef.current;
    const canvas = cropper.getCanvas();
    const imageURL = canvas?.toDataURL();
    try {
      if (logoImageType === 'primary') {
        const uploadedImg = {
          link: imageURL,
          name: primaryLogoName
        };
        setPrimaryLogoPreview(uploadedImg);
        setDisablePrimary(false);
        setPrimaryLogo(null);
        inputRef.current.reset();
      } else if (logoImageType === 'small') {
        setLogoType('small');
        const img = new Image();
        img.src = imageURL;
        {
          const uploadedImg = {
            link: imageURL,
            name: smallLogoName
          };
          setSmallLogoPreview(uploadedImg);
          setDisableSmall(false);
          inputRef.current.reset();
          setSmallLogo(null);
        }
      }
      setIsAvatarModalOpen(false);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleCloseAvatar = () => {
    setIsAvatarModalOpen(false);
    setSelectedAvatar(null);
  };

  return (
    <Modal
      show={true}
      isCloseButton={false}
      parentClassName="cs-common-modal-overlay"
      className="cs-md-modal cs-profile-update"
      handleClose={() => console.log('called')}
      title={logoImageConfig[logoImageType] || logoImageConfig.default}
      body={bodyElement()}
      saveButtonLabel="Save"
      cancelButtonLabel="Cancel"
      handleOnCancel={handleCloseAvatar}
      handleOnSave={uploadType ? handleLogoUpload : handleSaveAvatar}
      disabled={selectedAvatar === null && uploadedImage === ''}
    />
  );
};

// PROPS TYPE
UpdateAvatar.propTypes = {
  setIsAvatarModalOpen: PropTypes.func,
  handleAvatarSelected: PropTypes.func,
  selectedAvatar: PropTypes.string,
  setLogoType: PropTypes.string,
  primaryLogoName: PropTypes.string,
  smallLogoName: PropTypes.string,
  inputRef: PropTypes.func,
  setPrimaryLogoPreview: PropTypes.func,
  setPrimaryLogo: PropTypes.func,
  setDisableSmall: PropTypes.func,
  setDisablePrimary: PropTypes.func,
  setSmallLogo: PropTypes.func,
  setSmallLogoPreview: PropTypes.func,
  logoImageType: PropTypes.string,
  smallLogo: PropTypes.string,
  uploadType: PropTypes.string,
  logoFile: PropTypes.object,
  setSelectedAvatar: PropTypes.func,
  selectedAvatarFromDb: PropTypes.string
};

export default UpdateAvatar;
