import React from 'react';
import Skeleton from 'react-loading-skeleton';

// COMPONENT IMPORTS
import SkeletonCategoryTable from './SkeletonCategoryTable';

/* ============================== SKELETON REQUEST ============================== */
const SkeletonRequest = () => {
  return (
    <div className="cs-section-topbar">
      <div className="cs-title">
        <h1 className="default-semi-bold-h1 cs-neutral-100">
          <Skeleton width={200} />
        </h1>
      </div>
      <div className="skeleton-manage-container for-flex-des">
        <div className="skeleton-tabs-container">
          <h1 className="skeleton-tab-1">
            <Skeleton width={180} />
          </h1>
          <h1 className="skeleton-tab-2">
            <Skeleton width={180} />
          </h1>
        </div>
        <div>
          <Skeleton width={200} height={30} />
        </div>
      </div>
      <div className="skeleton-manage-container for-flex-mob">
        <div>
          <Skeleton width="100%" height={30} />
        </div>
        <div className="skeleton-tabs-container">
          <h1 className="skeleton-tab-1">
            <Skeleton width={125} />
          </h1>
          <h1 className="skeleton-tab-2">
            <Skeleton width={125} />
          </h1>
        </div>
      </div>
      <div className="skeleton-table">
        <SkeletonCategoryTable tableData={[1, 2, 3]} />
      </div>
    </div>
  );
};

export default SkeletonRequest;
