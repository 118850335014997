import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';

import './assets/css/variables.css';

import 'react-loading-skeleton/dist/skeleton.css';
import { persistor, store } from './store';
import FallBack from './components/FallBack';
import { PersistGate } from 'redux-persist/lib/integration/react';

const checkAndSetPass = () => {
  const output = window.localStorage.getItem('tempPass');
  const webPass = process.env.REACT_APP_WEB_PASSWORD;
  if (output === null && process.env.REACT_APP_WEB_PASSWORD !== 'app_login') {
    const disPass = window.prompt('Enter password:');
    if (disPass === webPass) {
      window.localStorage.setItem('tempPass', webPass);
      return true;
    } else {
      return false;
    }
  }
  return true;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
const errorHandler = (error, errorInfo) => {
  console.log('error:- ', error);
  console.log('errorInfo:- ', errorInfo);
};
root.render(
  <ErrorBoundary FallbackComponent={FallBack} onError={errorHandler}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SkeletonTheme baseColor="#E0E0E0" highlightColor="#c2c2c2">
            {checkAndSetPass() ? <App /> : <FallBack />}
          </SkeletonTheme>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
