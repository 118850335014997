import React, { useState, useEffect } from 'react';
import { Form, Dropdown, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

// API
import { completeClientsList } from '../../../slices/clientSlice';

const SelectCompanyDropdown = ({
  companies,
  setSelectedCompanies,
  selectedCompanies,
  isIndividual
}) => {
  const [search, setSearch] = useState('');
  const searchResultArray = companies?.filter((company) => {
    if (isIndividual) {
      const firstNameMatch = company.first_name.toLowerCase().includes(search.toLowerCase());
      const lastNameMatch = company.last_name.toLowerCase().includes(search.toLowerCase());
      return firstNameMatch || lastNameMatch;
    } else {
      return company.company_name.toLowerCase().match(search.toLowerCase());
    }
  });

  const handleDropdownItemClick = (company) => {
    if (isIndividual) {
      setSelectedCompanies([...selectedCompanies, company]);
    } else {
      setSelectedCompanies([company]);
    }
    setSearch('');
  };
  return (
    <div className="cs-create-company">
      <Form.Group>
        <Form.Label className="cs-neutral-100">
          {isIndividual ? 'Choose individual' : 'Choose company'}
        </Form.Label>
        <Dropdown className="cs-form-dropdown">
          <Dropdown.Toggle>
            <p
              className={`main-filter cs-light-body-text-m ${
                selectedCompanies.length === 0 ? 'cs-neutral-70' : ''
              }`}>
              {selectedCompanies.length > 0
                ? selectedCompanies[selectedCompanies.length - 1].company_name ||
                  'Select Individual'
                : isIndividual
                  ? 'Select Individual'
                  : 'Select company'}
            </p>
            <span className="cs-neutral-90">
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Form.Group className="cs-form-group">
              <Form.Control
                type="text"
                placeholder={isIndividual ? 'Search Individual' : 'Search company'}
                value={search}
                className={search ? 'cs-input-field-active' : ''}
                onChange={(event) => setSearch(event.target.value)}
              />
              <span className="input-field-icon cs-neutral-70">
                <CapsyncIcon title="search-outlined" size="18" />
              </span>
            </Form.Group>
            <div className="cs-scroller">
              {searchResultArray?.map((company, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      !selectedCompanies?.includes(company) && handleDropdownItemClick(company);
                      selectedCompanies?.includes(company) &&
                        toast.error(
                          `${company?.first_name} ${company?.first_name} is Already Selected !`
                        );
                    }}
                    className={
                      selectedCompanies?.includes(company) ? 'cs-selected-dropdown cs-disabled' : ''
                    }>
                    {isIndividual
                      ? company.first_name + ' ' + company.last_name
                      : company.company_name}
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <div className="cs-regular-sub-heading-xs cs-neutral-100"></div>
    </div>
  );
};

const SelectedCompanyTable = ({ selectedCompanies, isIndividual }) => {
  return (
    selectedCompanies &&
    selectedCompanies.length !== 0 && (
      <div className="partners-details-table">
        <span className="cs-regular-body-text-m cs-neutral-80">
          {isIndividual ? 'Partners details' : 'Company details'}
        </span>
        {
          <Table className="cs-table" responsive>
            <thead className="cs-thead">
              <tr>
                <th className="cs-regular-sub-heading-m cs-neutral-100">
                  {isIndividual ? 'Name' : 'Company name'}
                </th>
                <th className="cs-regular-sub-heading-m cs-neutral-100">
                  {isIndividual ? 'Email' : 'No. of partners'}
                </th>
              </tr>
            </thead>
            <tbody className="cs-tbody">
              {selectedCompanies &&
                selectedCompanies.map((company, rowIndex) => (
                  <tr key={rowIndex}>
                    <td key={rowIndex + 1} className="cs-regular-sub-heading-m cs-neutral-90">
                      {isIndividual
                        ? company.first_name + ' ' + company.last_name
                        : company.company_name}
                    </td>
                    <td key={rowIndex + 2} className="cs-regular-sub-heading-m cs-neutral-90">
                      <span>{isIndividual ? company.email : company.member_count}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        }
      </div>
    )
  );
};

/* ============================== ASSOCIATE COMPANY ============================== */
const AssociateCompany = ({
  setSelectedCompanyName,
  isChecked,
  setIsChecked,
  type,
  typeModal,
  companies,
  dropdownValidation,
  setDropdownValidation
}) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleCheckboxClick = () => {
    if (typeModal === 'IndividualClient' && Object.keys(type)?.length > 0) {
      if (companies?.length !== 0) {
        setIsChecked(!isChecked);
      }
    } else if (typeModal === 'TeamCollaboration') {
      if (companies?.length !== 0) {
        setIsChecked(!isChecked);
      }
    }
  };

  useEffect(() => {
    if (isChecked === false) {
      setSelectedCompanies([]);
      setDropdownValidation('');
    }
  }, [isChecked]);

  useEffect(() => {
    if (selectedCompanies.length > 0) {
      const companyId = selectedCompanies[selectedCompanies.length - 1].id;
      setSelectedCompanyName(companyId);
    } else {
      setSelectedCompanyName('');
    }
    setDropdownValidation('');
  }, [selectedCompanies]);

  return (
    <div className="associate-add-more">
      <Form.Group
        onClick={handleCheckboxClick}
        className={`associate-add-more ${companies?.length === 0 || (typeModal === 'IndividualClient' && !Object.keys(type)?.length) ? 'cs-disabled' : ''}`}>
        <Form.Check
          type="checkbox"
          checked={isChecked}
          disabled={
            companies?.length === 0 ||
            (typeModal === 'IndividualClient' && !Object.keys(type)?.length)
          }
          label={
            <span className="cs-light-body-text-m cursor-pointer d-flex">
              Associate this individual with company
            </span>
          }
          className="cs-form-check-box "
        />
      </Form.Group>
      {isChecked && (
        <React.Fragment>
          <SelectCompanyDropdown
            companies={companies}
            setSelectedCompanies={setSelectedCompanies}
            selectedCompanies={selectedCompanies}
          />
          {dropdownValidation && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">
              {dropdownValidation}
            </span>
          )}
          <SelectedCompanyTable selectedCompanies={selectedCompanies} />
        </React.Fragment>
      )}
    </div>
  );
};
export default AssociateCompany;

export const AssociateIndividual = ({
  setSelectedCompanyName,
  isChecked,
  setIsChecked,
  dropdownValidation,
  setDropdownValidation,
  selectedUsersDetails,
  editCompanyDetails,
  selectedCompanyName
}) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleCheckboxClick = () => {
    if (companies && companies.length > 0) {
      setIsChecked(!isChecked);
    }
  };
  useEffect(() => {
    getAllCollaborationUsersList({
      page: false,
      limit: false,
      isPaginate: false
    });
  }, []);
  const getAllCollaborationUsersList = async (params) => {
    try {
      const response = await dispatch(completeClientsList(params));
      const companiesData = response.payload;
      if (companiesData.code === 200) {
        if (
          (selectedUsersDetails && selectedUsersDetails.length > 0) ||
          (editCompanyDetails && editCompanyDetails.length > 0)
        ) {
          const individualList =
            editCompanyDetails && editCompanyDetails.length > 0
              ? editCompanyDetails
              : selectedUsersDetails;
          const filteredIndividualList = companiesData.data.records.filter(
            (item2) =>
              !individualList.some((item1) => item1.id === item2.id || item1.user_id === item2.id)
          );
          setCompanies(filteredIndividualList);
        } else {
          setCompanies(companiesData.data.records);
        }
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  useEffect(() => {
    if (isChecked === false) {
      setSelectedCompanies([]);
      setDropdownValidation('');
    }
  }, [isChecked]);
  useEffect(() => {
    if (selectedCompanies.length > 0) {
      // const companyId = selectedCompanies[selectedCompanies.length - 1].id;
      const companyId = selectedCompanies.map((item) => item.id);
      setSelectedCompanyName(companyId);
    } else {
      setSelectedCompanyName('');
    }
    setDropdownValidation('');
  }, [selectedCompanies]);

  return (
    <div className="associate-add-more">
      <Form.Group
        onClick={handleCheckboxClick}
        className={`associate-add-more ${companies && companies.length === 0 ? 'cs-disabled' : ''}`}>
        <Form.Check
          type="checkbox"
          checked={isChecked}
          disabled={companies && companies.length === 0}
          label={
            <span className="cs-light-body-text-m cursor-pointer d-flex">
              Associate already added individual with company
            </span>
          }
          className="cs-form-check-box "
        />
      </Form.Group>
      {isChecked && (
        <React.Fragment>
          <SelectCompanyDropdown
            companies={companies}
            isIndividual={true}
            setSelectedCompanies={setSelectedCompanies}
            selectedCompanies={selectedCompanies}
          />
          {dropdownValidation && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">
              {dropdownValidation}
            </span>
          )}
          <SelectedCompanyTable selectedCompanies={selectedCompanies} isIndividual={true} />
        </React.Fragment>
      )}
    </div>
  );
};

// PROPS TYPE
AssociateCompany.propTypes = {
  setSelectedCompanyName: PropTypes.func,
  setIsChecked: PropTypes.func,
  type: PropTypes.object,
  typeModal: PropTypes.string,
  companies: PropTypes.array,
  isChecked: PropTypes.bool,
  dropdownValidation: PropTypes.string,
  setDropdownValidation: PropTypes.func
};

// PROPS TYPE
AssociateIndividual.propTypes = {
  setSelectedCompanyName: PropTypes.func,
  setIsChecked: PropTypes.func,
  isChecked: PropTypes.bool,
  dropdownValidation: PropTypes.string,
  setDropdownValidation: PropTypes.func,
  selectedUsersDetails: PropTypes.array,
  editCompanyDetails: PropTypes.array,
  selectedCompanyName: PropTypes.array
};

// PROPS TYPE
SelectedCompanyTable.propTypes = {
  selectedCompanies: PropTypes.string,
  isIndividual: PropTypes.bool
};

// PROPS TYPE
SelectCompanyDropdown.propTypes = {
  setSelectedCompanies: PropTypes.func,
  selectedCompanies: PropTypes.string,
  companies: PropTypes.array,
  isIndividual: PropTypes.bool
};
