import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import StepOne from './StepOne';
import AuthBanner from '../../AuthBanner';
import Review from './Review';
import AuthHead from '../../AuthHead';
import CapsyncIcon from '../../../components/CapsyncIcon';
import TokenExpired from './TokenExpired';
import { AUTH_CONTENT } from '../../../constants/content';
import { emailVerification, getfirmInquiry, useUserID } from '../../../slices/authSlice';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

const BusinessForm = (props) => {
  const { token } = useParams();

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {
    user,
    businessFormData: { step5, completed_step: completedstep }
  } = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [showForm, setShowForm] = useState(null);
  const [headDetail, setHeadDetail] = useState({ title: '', description: '' });
  const [formData, setFormData] = useState({
    registered_advisor: '',
    crd_number: '',
    street_name: '',
    city: '',
    state: '',
    zip_code: '',
    description: '',
    website: '',
    company_name: '',
    business_type: '',
    ein_number: '',
    incorporation_date: '',
    number_of_clients: '',
    types_of_clients: '',
    industry: ''
  });
  const emailVerificationCalled = useRef(false);
  const getfirmInquiryCalled = useRef(false);

  const userId = useUserID();

  useEffect(() => {
    completedstep >= 0 && setPage(completedstep);
  }, [completedstep]);

  useEffect(() => {
    setHeadDetail(detail);
  }, [page]);

  useEffect(() => {
    if (!emailVerificationCalled.current && user && !user.isEmailVerified) {
      emailVerificationCalled.current = true;
      dispatch(emailVerification(token));
    }
  }, []);

  useEffect(() => {
    if (!getfirmInquiryCalled.current && userId) {
      getfirmInquiryCalled.current = true;
      dispatch(getfirmInquiry(userId));
    }

    user && setShowForm(user.isEmailVerified);
  }, [user]);

  useEffect(() => {
    showForm === true && step5 && step5.is_inquiry_confirm && navigation('/confirmed-business');
  }, [step5]);

  const nextStep = () => setPage(page + 1);

  const prevStep = () => setPage(page - 1);

  let detail = {
    title: AUTH_CONTENT.BUSINESS_TITLE_ONE,
    description: AUTH_CONTENT.BUSINESS_DETAIL_ONE
  };
  const pageDisplay = () => {
    switch (page) {
      case 0:
        detail = {
          title: AUTH_CONTENT.BUSINESS_TITLE_ONE,
          description: AUTH_CONTENT.BUSINESS_DETAIL_ONE
        };
        return <StepOne nextStep={nextStep} setFormData={setFormData} formData={formData} />;
      case 1:
        detail = {
          title: AUTH_CONTENT.BUSINESS_TITLE_TWO,
          description: AUTH_CONTENT.BUSINESS_DETAIL_TWO
        };
        return (
          <StepTwo
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 2:
        detail = {
          title: AUTH_CONTENT.BUSINESS_TITLE_THREE,
          description: AUTH_CONTENT.BUSINESS_DETAIL_THREE
        };
        return (
          <StepThree
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 3:
        detail = {
          title: AUTH_CONTENT.BUSINESS_TITLE_FOUR,
          description: AUTH_CONTENT.BUSINESS_DETAIL_FOUR
        };
        return (
          <StepFour
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 4:
        detail = {
          title: AUTH_CONTENT.BUSINESS_TITLE_REVIEW,
          description: AUTH_CONTENT.BUSINESS_DETAIL_REVIEW
        };
        return (
          <Review
            prevStep={prevStep}
            setPage={setPage}
            setFormData={setFormData}
            formData={formData}
          />
        );

      default:
        return null;
    }
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              {showForm === true && completedstep >= 0 && (
                <React.Fragment>
                  <AuthHead title={headDetail.title} description={headDetail.description} />
                  {page != 4 && (
                    <div className="multi-step-header">
                      <div className="multi-step-title">
                        <h3 className="cs-regular-body-text-s cs-neutral-100">
                          {' '}
                          Step {page + 1}/4{' '}
                        </h3>
                        {page === 3 && (
                          <span className="cs-success">
                            <CapsyncIcon title="verification-outlined" size="14" />
                          </span>
                        )}
                      </div>
                      <div className="multi-step-progressbar">
                        <div
                          style={{
                            width:
                              page === 0 ? '25%' : page === 1 ? '40%' : page === 2 ? '75%' : '100%'
                          }}></div>
                      </div>
                    </div>
                  )}
                  <div className={`multi-step-body ${page === 0 ? ' cs-step-one' : ''}`}>
                    {pageDisplay()}
                  </div>
                </React.Fragment>
              )}
              {showForm === false && <TokenExpired />}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BusinessForm;
