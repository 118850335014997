import * as React from 'react';

// COMPONENT IMPORTS
import Layout from '../pages/Layout';
import Request from '../pages/Request';
import Settings from '../pages/Settings';
import Branding from '../pages/Branding';
import Dashboard from '../pages/dashboard';
import ProtectedRoutes from './ProtectedRoutes';
import CompanyClient from '../pages/dashboard/CompanyClient';
import IndividualClient from '../pages/dashboard/IndividualClient';

/* ============================== DASHBOARD ROUTES ============================== */
const DashboardRoutes = {
  path: 'dashboard',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '',
      element: <Layout />,
      children: [
        { path: '', element: <Dashboard /> },
        { path: 'individual', element: <IndividualClient /> },
        { path: 'company', element: <CompanyClient /> },
        { path: 'request', element: <Request /> },
        { path: 'admin', element: 'admin' },
        { path: 'settings/:tabName', element: <Settings /> }
      ]
    },
    { path: 'set-up', element: <Branding /> }
  ]
};

export default DashboardRoutes;
