import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== DATE PICKER BOX ============================== */
const DatePickerBox = ({
  label,
  name,
  values,
  disabled = false,
  maxDate,
  minDate,
  errors,
  touched,
  onChange
}) => {
  return (
    <Form.Group className="cs-form-group last-form-field resp-last-field">
      {label && <Form.Label>{label}</Form.Label>}
      <DatePicker
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        name={name}
        value={values[name]}
        disabled={disabled}
        onChange={onChange}
        clearIcon={null}
        className="cs-input-field-active select-date-range cs-date-picker-calender cs-date-picker"
        calendarIcon={
          <span className="cs-neutral-90">
            <CapsyncIcon title="calender-outlined" size="18" />
          </span>
        }
        format="MM/dd/y"
        {...{ maxDate, minDate }}
      />
      {errors[name] && touched[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
DatePickerBox.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string,
  minDate: PropTypes.string
};

export default DatePickerBox;
