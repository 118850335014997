import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/* ============================== AUTH REDIRECT ============================== */
const useAuthRedirect = () => {
  const navigate = useNavigate();

  const { user, mfa, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && mfa && mfa.verified && mfa.verified === true) {
      navigate('/dashboard');
    } else if (!isAuthenticated) {
      navigate('/');
    } else if ((user && mfa && !!mfa.verified) || (user && !mfa)) {
      navigate('/mfa-setup');
    }
  }, [user, mfa, isAuthenticated, navigate]);
};

export default useAuthRedirect;
