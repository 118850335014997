import React from 'react';
import { Accordion, Col, Container, Row, Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON BRANDING ============================== */
const SkeletonBranding = () => {
  return (
    <div className="branding-section">
      <Container fluid>
        <div className="branding-container">
          <Row>
            <Col lg={5}>
              <div className="branding-left">
                <div className="cs-accordion cs-branding-accordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <div className="skeleton-branding-item">
                        <span className="cs-regular-h3 cs-neutral-100">
                          <Skeleton width={100} />
                        </span>
                        <Skeleton width={30} />
                      </div>
                      <Accordion.Body>
                        <p className="cs-light-body-text-l cs-neutral-80">
                          <Skeleton width={400} />
                        </p>
                        <Form>
                          <Row>
                            <Col>
                              <Form.Group className="cs-form-group">
                                <Skeleton width={400} />
                              </Form.Group>
                              <div className="sm-btn cs-regular-h5 skeleton-continue">
                                <Skeleton width={100} />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0">
                    {Array(3)
                      .fill(0)
                      .map((_, index) => {
                        return (
                          <Accordion.Item eventKey="0" key={index}>
                            <div className="skeleton-branding-item">
                              <span className="cs-regular-h3 cs-neutral-100">
                                <Skeleton width={150} />
                              </span>
                              <Skeleton width={30} />
                            </div>
                          </Accordion.Item>
                        );
                      })}
                  </Accordion>
                </div>
                <div className="cs-btn-group">
                  <Skeleton width={50} />

                  <Skeleton width={50} />
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="branding-right">
                <h3 className="cs-regular-h3 cs-neutral-100">
                  <Skeleton width={100} />
                </h3>
                <Skeleton width={100} />
                <div className="preview-brand">
                  <Row className="align-items-center">
                    <Col>
                      <div className="template-left-side">
                        <div className="template-bg">
                          <Skeleton style={{ width: '100%', height: '100%' }} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SkeletonBranding;
