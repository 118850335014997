import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// INITIAL STATE
import axios from '../config/api';
import CONFIG from '../constants/config';
import config from '../constants/config';

// BRANDING API CALL
export const userSignUp = createAsyncThunk('auth/userSignUp', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/signup`, data);
  return res;
});

// VALIDATE DOMAIN NAME
export const validateDomainName = createAsyncThunk('auth/validateDomainName', async (domain) => {
  const res = await axios.get(`${CONFIG.BASE_URL}firm_brand/check_domain/${domain}`);
  return res;
});

// ADDING CLIENT DATA
export const addingClientData = createAsyncThunk('auth/addingClientData', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}firm_brand/add_brand_data`, data);
  return res;
});

// UPLOAD WELCOME IMAGE
export const uploadWelcomeImage = createAsyncThunk(
  '/firm_brand/upload_images',
  async (data, { rejectWithValue }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}firm_brand/upload_images`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// UPDATE CLIENT DATA
export const updateClientData = createAsyncThunk('auth/updateClientData', async (data) => {
  const res = await axios.put(`${CONFIG.BASE_URL}firm_brand/update_brand_data`, data);
  return res;
});

// GET BRAND DETAILS
export const getBrandDetails = createAsyncThunk(
  'branding/get_brand_details',
  async (data, { rejectWithValue }) => {
    const res = await axios.get(`${CONFIG.BASE_URL}firm_brand/get_firm/${data}`);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// Delete Uploaded Image
export const deleteUploadImage = createAsyncThunk(
  'firm_brand/delete_image',
  async ({ id, userId }) => {
    const res = await axios.delete(`${CONFIG.BASE_URL}firm_brand/delete_image/${id}`, {
      data: { user_id: userId }
    });
    return res;
  }
);

// INITIAL STATE
const initialState = {
  brandRecord: {},
  status: null,
  message: ''
};

/* ============================== BRAND DETAILS SLICE ============================== */
export const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBrandDetails.fulfilled, (state, { payload }) => {
        state.brandRecord = payload.data;
      })
      .addCase(uploadWelcomeImage.fulfilled, (state, { payload }) => {
        const { message } = payload;
        state.status = config.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(uploadWelcomeImage.rejected, (state, { payload }) => {
        state.status = config.STATUS.ERROR;
        state.message = payload;
      });
  }
});

const { reducer } = brandingSlice;
export default reducer;
