import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import CustomTable from '../../../components/CustomTable';
import SkeletonSettingsTable from '../../components/skeleton/SkeletonSettingsTable';

// API
import { useUser } from '../../../slices/authSlice';
import { billingHistory } from '../../../slices/auditLogsSlice';

/* ============================== BILLING HISTORY ============================== */
const BillingHistory = () => {
  const dispatch = useDispatch();
  const user = useUser();

  const [billingData, setBillingData] = useState([]);

  useEffect(() => {
    onPaginationValueChange({
      page: 1,
      limit: 10,
      isPaginate: false
    });
  }, []);

  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false
    };
    getAllCollaborationUsersList(paginationLimits);
  };

  const getAllCollaborationUsersList = (params) => {
    dispatch(billingHistory({ userID: user?.id, userRole: user.role_id, params })).then((res) => {
      setBillingData(res?.payload?.data);
    });
  };

  const COLUMNS = [
    {
      label: 'Date',
      renderCell: (item) => <span>{moment(item.transaction_date).format('L')}</span>,
      width: 254
    },
    {
      label: 'Name',
      renderCell: (item) => (
        <span>
          {' '}
          {user.role_id === 5
            ? item?.payment_by_user?.first_name + ' ' + item?.payment_by_user?.last_name
            : item?.payment_for_user?.first_name + ' ' + item?.payment_for_user?.last_name}{' '}
        </span>
      ),
      width: 254
    },

    ...(user.role_id === 5
      ? [
          {
            label: 'Role',
            renderCell: (item) => <span>{item?.payment_by_user?.user_role?.role}</span>,
            width: 254
          },
          {
            label: 'Client',
            renderCell: (item) => (
              <span>
                {item?.payment_for_user?.first_name + ' ' + item?.payment_for_user?.last_name}
              </span>
            ),
            width: 254
          }
        ]
      : []),
    {
      label: 'Details',
      renderCell: (item) => {
        const parsedItem = JSON.parse(item?.payment_response);
        return <span>{parsedItem?.description}</span>;
      },
      width: 254
    },
    {
      label: 'Amount',
      renderCell: (item) => {
        const parsedItem = JSON.parse(item?.payment_response);
        return <span>$ {parsedItem?.amount / 100}</span>;
      },
      width: 254
    },
    {
      label: 'Invoice',
      renderCell: (item) => (
        <Link
          to={item?.invoice_url}
          target="_blank"
          className="cs-regular-body-text-l export-btn cs-primary cursor-pointer">
          {' '}
          Export to Pdf{' '}
        </Link>
      ),
      width: 254
    }
  ];

  const [loading] = useOutletContext();

  return (
    <div className="cs-setting-page cs-audit-logs-sec">
      {loading ? (
        <SkeletonSettingsTable tableData={[1, 2, 3, 4, 5, 6]} />
      ) : (
        <CustomTable
          columns={COLUMNS}
          totalRecords={
            billingData?.paymentHistoryPagination?.itemCount
              ? billingData?.paymentHistoryPagination?.itemCount
              : billingData?.userPaymentHistory?.rows.length
          }
          data={billingData?.userPaymentHistory?.rows}
          isPaginate={true}
          onPaginationValueChange={onPaginationValueChange}
          columnVisible={false}
        />
      )}
    </div>
  );
};

export default BillingHistory;
