import React from 'react';
import Skeleton from 'react-loading-skeleton';

// COMPONENT IMPORTS
import SkeletonCategoryTable from './SkeletonCategoryTable';

/* ============================== SKELETON IND COMPANY ============================== */
const SkeletonIndCompany = () => {
  return (
    <div className="cs-section-topbar">
      <div className="skeleton-manage-container wm-skeleton-header for-flex-des">
        <div>
          <Skeleton width={180} height={30} />
        </div>
        <div>
          <Skeleton width={200} height={30} />
        </div>
        <div>
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="skeleton-manage-container wm-skeleton-header for-flex-mob">
        <div className="skeleton-financial-header">
          <div>
            <Skeleton width={180} />
          </div>
          <div>
            <Skeleton width={40} height={30} />
          </div>
        </div>
        <div>
          <Skeleton width="100%" height={30} />
        </div>
      </div>
      <div className="skeleton-table">
        <SkeletonCategoryTable tableData={[1, 2, 3]} />
      </div>
    </div>
  );
};

export default SkeletonIndCompany;
