import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

// COMPONENT IMPORTS
import Dashboard from '../../assets/images/brand-setup/DashboardTemplate.svg';

/* ============================== PREVIEW BRANDING ============================== */
const PreviewBranding = ({ logo }) => {
  return (
    <div className="preview-brand">
      <svg>
        <defs>
          <pattern id="brand-image" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#brand-logo" transform="matrix(0.00182149 0 0 0.0064663 0 -0.00113843)" />
          </pattern>
          <image id="brand-logo" width="549" height="155" href={logo} />
        </defs>
      </svg>
      <ReactSVG src={Dashboard} />
    </div>
  );
};

// PROPS TYPE
PreviewBranding.propTypes = {
  logo: PropTypes.string
};

export default PreviewBranding;
