import React from 'react';
import Skeleton from 'react-loading-skeleton';

/* ============================== SKELETON SIDEBAR ============================== */
const SkeletonSidebar = () => {
  return (
    <div className="cs-main-sidebar skeleton-main-sidebar">
      <div className="sidebar-header">
        <div className="cs-logo">
          <Skeleton width={132} />
        </div>
      </div>
      <div className="sidebar-nav">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <div key={index} className="skeleton-main-nav-item">
              <div className="skeleton-menu-icon">
                <Skeleton width={22} height={22} />
              </div>
              <div className="cs-regular-sub-heading-m">
                <Skeleton width={150} height={22} />
              </div>
            </div>
          ))}
      </div>
      <div className="sidebar-add-account">
        <Skeleton />
      </div>
    </div>
  );
};

export default SkeletonSidebar;
