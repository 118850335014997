import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

// COMPONENT IMPORTS
import { usAreaCodes } from '../../config/countryAreaCodes';

/* ============================== PHONE INPUT ============================== */
const PhoneInputField = ({ label, name, values, setFieldValue, errors, submitCount }) => {
  const phoneHandleChange = (fieldName, val) => {
    if (val) {
      if (
        isValidPhoneNumber(val) === true &&
        usAreaCodes.includes(Number(val.split('+1')[1].substr(0, 3))) &&
        val.split('+1')[1].length == 10
      ) {
        setFieldValue(fieldName, val);
      } else {
        setFieldValue(fieldName, val);
      }
    } else {
      setFieldValue(fieldName, '');
    }
  };

  const handleCursorPosition = (e) => {
    const input = e.target;
    const countryCodeLength = '+1 '.length;

    if (input.selectionStart <= countryCodeLength) {
      input.setSelectionRange(countryCodeLength, countryCodeLength);
    }
  };

  return (
    <Form.Group className="cs-form-group">
      <Form.Label>{label}</Form.Label>
      <PhoneInput
        limitMaxLength={15}
        international
        countryCallingCodeEditable={false}
        addInternationalOption={false}
        autoComplete="off"
        defaultCountry="US"
        name={name}
        withCountryCallingCode={false}
        value={values[name]}
        className={`form-control ${values[name] ? 'cs-input-field-active' : ''}`}
        countries={['US']}
        onChange={(e) => phoneHandleChange(name, e)}
        numberInputProps={{ onSelect: handleCursorPosition }}
      />
      {errors[name] && submitCount > 0 && (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
PhoneInputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired
};

export default PhoneInputField;
