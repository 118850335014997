import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from './Modal';
import CapsyncIcon from './CapsyncIcon';

/* ============================== INACTIVITY LOGOUT MODAL ============================== */
const InactivityLogoutModal = ({ remainingSeconds, onStay, handleLogoutUser }) => {
  useEffect(() => {
    if (remainingSeconds === 0) {
      handleLogoutUser();
    }
  }, [remainingSeconds]);

  return (
    <Modal
      body={
        <div className="cs-text-center log-out-popup">
          <span className="icon cs-icon">
            <CapsyncIcon title="error-validation" size="100" />
          </span>
          <p className="cs-regular-body-text-m cs-neutral-90 text-center">
            To ensure the security of your account and data, you will be automatically logged out
            in&nbsp;
            <span className="cs-neutral-100">
              {Math.floor(remainingSeconds / 60)}:{remainingSeconds % 60 < 10 ? '0' : ''}
            </span>
            {remainingSeconds % 60} due to inactivity.
          </p>
        </div>
      }
      show={true}
      isCloseButton={false}
      handleOnCancel={onStay}
      handleOnSave={handleLogoutUser}
      title="No activity detected"
      cancelButtonLabel="Stay here"
      saveButtonLabel=" Log out"
      parentClassName="cs-common-modal-overlay"
      className="cs-md-modal"
      modalFooterClass="cs-center-btn"
    />
  );
};

// PROPS TYPE
InactivityLogoutModal.propTypes = {
  remainingSeconds: PropTypes.number,
  onStay: PropTypes.func,
  handleLogoutUser: PropTypes.func
};

export default InactivityLogoutModal;
