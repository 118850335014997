import React, { useState } from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import { localObjectClear } from '../utils/system';
import MFAOption from '../components/MFASetup/MFAOptions';
import Logo from '../assets/images/brand/capsync-logo.svg';
import { MFA_OPTIONS_DATA, MFA_TYPES } from '../constants/system';

// HOOKS IMPORTS
import useAuthRedirect from '../hooks/useAuthRedirect';
import useRemoveServerError from '../hooks/useRemoveServerError';

// API
import { setMFA } from '../slices/mfaSlice';
import { authUserLogout, generateMFAOTP } from '../slices/authSlice';

/* ============================== MFA SETUP ============================== */
const MFASetUp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useAuthRedirect();
  useRemoveServerError();

  const { user } = useSelector((state) => state.auth);

  const [selectedType, setSelectedType] = useState(null);

  const handleRedirect = (type) => {
    dispatch(setMFA({ type: type }));
    if (type === MFA_TYPES.AUTHENTICATOR && user && user.users_mfa_info.mfa_google === false) {
      dispatch(
        generateMFAOTP({ mfaType: MFA_TYPES.AUTHENTICATOR, payload: { user_id: user && user.id } })
      );
      navigate('/authenticator-setup');
    } else if (type === MFA_TYPES.AUTHENTICATOR) {
      navigate('/verification-pin');
    } else if (type === MFA_TYPES.EMAIL) {
      dispatch(generateMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload: { user_id: user && user.id } }));
      navigate('/verification-pin');
    }
  };

  const handleLogoutUser = () => {
    dispatch(authUserLogout());
    navigate('/');
    localObjectClear();
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6} className="p-0">
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Multi-factor authentication</h1>
                </div>
                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                  Choose your preferred authentication method
                </p>
              </div>
              <div className="cs-auth-form">
                <div className="mfa-row">
                  <Row>
                    {MFA_OPTIONS_DATA.map((option, index) => (
                      <MFAOption
                        key={index}
                        {...option}
                        callback={handleRedirect}
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                        delay={100}
                      />
                    ))}
                  </Row>

                  <Row>
                    <Col>
                      <span className="back-to-sign-in">
                        <Button
                          className="cs-btn-tertiary lg-btn cs-regular-h5"
                          onClick={handleLogoutUser}>
                          {' '}
                          Back to sign in{' '}
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MFASetUp;
