import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import authImg from '../assets/images/auth-image.svg';

/* ============================== AUTH BANNER ============================== */
const AuthBanner = () => {
  return (
    <Col lg={6} className="d-none d-lg-block">
      <div className="cs-left-section">
        <div className="cs-content-box cs-regular-sub-heading-m cs-neutral-100">
          <h1 className="cs-semi-bold-h1 cs-primary">Your New Immersive Digital Finance Hub.</h1>
          <p>Sync your real-time digital Personal Financial Statement with CapSync.</p>
          <Link
            className="cs-btn-secondary cs-regular-h5 sm-btn"
            title="Learn More"
            target="_blank">
            Learn More
          </Link>
        </div>
        <div className="auth-image">
          <Image src={authImg} alt="Capsync Authentication" />
        </div>
      </div>
    </Col>
  );
};

export default AuthBanner;
