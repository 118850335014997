import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Button, FormLabel } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import SelectBox from '../../../components/FormFields/SelectBox';
import CONFIG from '../../../constants/config';
import { stepFourValidationSchema } from './Validation';
import { firmInquiry, resetForm, useUserID } from '../../../slices/authSlice';
import { compareObject, createDropdownArray, createDropdownObject } from '../../../utils/system';
import { INDUSTRY_OPTIONS } from '../../../constants/system';

const StepFour = (props) => {
  const { nextStep, prevStep, isReview } = props;

  const dispatch = useDispatch();
  const {
    status: authStatus,
    message,
    businessFormData: { step4 }
  } = useSelector((state) => state.auth);

  const [initialValues, setInitialValues] = useState({ industry: '' });
  const [formError, setFormError] = useState(''); // Custom Error after form submit from API

  const userId = useUserID();

  useEffect(() => {
    step4 &&
      Object.keys(step4).length != 0 &&
      setInitialValues({
        ...initialValues,
        ...step4,
        industry: createDropdownObject(step4.industry)
      });
  }, [step4]);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: stepFourValidationSchema,
    onSubmit: (values) => {
      const formData = {
        user_id: Number(userId),
        industry: values.industry.value,
        category: 'Financial Services',
        inquiry_step: 4
      };
      if (compareObject(formData, step4)) {
        nextStep();
        return true;
      }

      dispatch(firmInquiry(formData))
        .unwrap()
        .then((response) => response.status && nextStep())
        .catch((error) => console.log('error', error.message));
    }
  });

  useEffect(() => {
    authStatus === CONFIG.STATUS.ERROR && setFormError(message);
  }, [authStatus]);

  useEffect(() => {
    if (formError != '') {
      setFormError('');
      dispatch(resetForm());
    }
  }, [values]);

  return (
    <div className="cs-auth-form">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <Form.Group className="cs-form-group">
              <FormLabel>Category</FormLabel>
              <h4 className="cs-regular-h4 cs-primary">Financial Services</h4>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="last-form-field resp-last-field">
              <SelectBox
                value={values.industry}
                errors={errors}
                touched={touched}
                handleChangeSelect={(option) => setFieldValue('industry', option)}
                options={createDropdownArray(INDUSTRY_OPTIONS)}
                placeholder="Select an industry"
                name="industry"
                label="Industry"
                disabled={isReview}
              />
            </div>
          </Col>
        </Row>
        {/* API ERROR MESSAGE DISPLAY */}
        <Row>
          <Col className="text-start">
            <span className="cs-light-body-text-m cs-danger">{formError}</span>
          </Col>
        </Row>
        {!isReview && (
          <Row>
            <Col>
              <div className="cs-modal-btn cs-center-btn">
                <Button className="cs-btn-secondary lg-btn cs-regular-h5" onClick={prevStep}>
                  Back
                </Button>
                <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

// PROPS TYPE
StepFour.propTypes = {
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  isReview: PropTypes.bool
};

export default StepFour;
