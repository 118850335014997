import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

// API
import { toggleSidebar } from '../../../slices/systemSlice';

/* ============================== SIDEBAR DROPDOWN ============================== */
const SideBarDropdown = ({ item }) => {
  const { key, iconOutlined = '', iconFilled, title, subItems = [] } = item;

  const dispatch = useDispatch();

  const { activeNav } = useSelector((state) => state.system);
  const { isSidebarOpen } = useSelector((state) => state.system);

  const [isFmExpanded, setIsFmExpanded] = useState(false);

  const isDropdownOpen = subItems.some(({ keyName }) => keyName === activeNav) || !isSidebarOpen;

  useEffect(() => {
    if (['individual', 'company'].some((keyName) => activeNav.includes(keyName))) {
      setIsFmExpanded(true);
    }
  }, [activeNav]);

  const handleNavItemClick = () => {
    if (isSidebarOpen && window.innerWidth <= 991) {
      dispatch(toggleSidebar());
    }
  };

  return (
    <li
      className={`cs-nav-dropdown nav-item dropdown cursor-pointer ${isFmExpanded ? 'navDropOpen' : ''}`}
      key={key}>
      {isSidebarOpen && (
        <span
          className={`cs-side-nav cs-regular-sub-heading-m cs-neutral-80 ${isDropdownOpen ? 'active' : ''}`}
          onClick={() => {
            setIsFmExpanded(!isFmExpanded);
          }}>
          <span className={`${iconOutlined} cs-icon cs-nav-icon`}>
            <CapsyncIcon
              title={`${isDropdownOpen ? iconFilled : 'user-multiple-outlined'}`}
              size="22"
            />
          </span>
          <span className="cs-nav-text">{title}</span>
          <span className="cs-drop-arrow cs-icon" id="chevron-down-filled">
            <CapsyncIcon title="chevron-down-filled" size="14" />
          </span>
        </span>
      )}
      {(isFmExpanded || !isSidebarOpen) && (
        <ul className="cs-dropdown-menu">
          {subItems.map((subItem) => {
            const { key, iconOutlined = '', iconFilled, title, path, keyName } = subItem;
            return (
              <li className="cs-nav-item nav-item" key={key} onClick={() => handleNavItemClick()}>
                <Link
                  to={path}
                  className={`cs-side-nav cs-regular-sub-heading-m cs-neutral-80 cs-nav-drop-item ${activeNav === keyName ? 'active' : ''}`}>
                  <span className={`${iconOutlined} cs-icon`}>
                    <CapsyncIcon
                      title={`${activeNav === keyName ? iconFilled : iconOutlined}`}
                      size="22"
                    />
                  </span>
                  <span className="cs-nav-text">{title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

// PROPS TYPE
SideBarDropdown.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.number.isRequired,
    iconOutlined: PropTypes.string.isRequired,
    iconFilled: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number.isRequired,
        iconOutlined: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        keyName: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

export default SideBarDropdown;
