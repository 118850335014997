import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Logo from '../assets/images/brand/capsync-logo.svg';

/* ============================== AUTH HEAD ============================== */
const AuthHead = (props) => {
  const { title, description } = props;

  return (
    <div className="cs-auth-header">
      <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
      {title && (
        <React.Fragment>
          <div className="cs-title">
            <h1 className="cs-semi-bold-h1">{title}</h1>
          </div>
          <p className="cs-regular-body-text-m cs-neutral-90 text-center">{description}</p>
        </React.Fragment>
      )}
    </div>
  );
};

// PROPS TYPE
AuthHead.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string
};

export default AuthHead;
