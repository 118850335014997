import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Accordion } from 'react-bootstrap';

import CapsyncIcon from '../../../components/CapsyncIcon';
import CONFIG from '../../../constants/config';
import { firmInquiry, useUserID } from '../../../slices/authSlice';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

const Review = (props) => {
  const { setPage, prevStep } = props;

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { status: authStatus, message } = useSelector((state) => state.auth);

  const [active, setActive] = useState(0);
  const [formError, setFormError] = useState(''); // Custom Error after form submit from API

  const userId = useUserID();

  const accordionItems = [
    'Business structure',
    'About your business',
    'Business address',
    'About your industry'
  ];

  useEffect(() => {
    authStatus === CONFIG.STATUS.ERROR && setFormError(message);
  }, [authStatus]);

  const handleSubmit = () => {
    const formData = {
      user_id: Number(userId),
      is_inquiry_confirm: true,
      inquiry_step: 5
    };
    dispatch(firmInquiry(formData))
      .unwrap()
      .then((response) => response.status && navigation('/thank-you'))
      .catch((error) => console.log('error', error.message));
  };

  return (
    <div className="cs-accordion auth-sign-up-scrollbar review-info-section">
      <Accordion defaultActiveKey={0}>
        <React.Fragment>
          {accordionItems.map((item, index) => {
            return (
              <Accordion.Item eventKey={index} key={index} onClick={() => setActive(index)}>
                <Accordion.Header>
                  <span className="cs-regular-h4 cs-neutral-100">{item}</span>
                  {active === index && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setPage(index);
                      }}>
                      <span className="cs-primary">
                        <CapsyncIcon title="edit-image-filled" size="14" />
                      </span>
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {index === 0 ? (
                    <StepOne isReview={true} />
                  ) : index === 1 ? (
                    <StepTwo isReview={true} />
                  ) : index === 2 ? (
                    <StepThree isReview={true} />
                  ) : index === 3 ? (
                    <StepFour isReview={true} />
                  ) : (
                    <Review />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </React.Fragment>
      </Accordion>
      {/* API ERROR MESSAGE DISPLAY */}
      <Row>
        <Col className="text-start">
          <span className="cs-light-body-text-m cs-danger">{formError}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="cs-modal-btn cs-center-btn">
            <Button className="cs-btn-secondary lg-btn cs-regular-h5" onClick={prevStep}>
              Back
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary lg-btn cs-regular-h5"
              onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

// PROPS TYPE
Review.propTypes = {
  setPage: PropTypes.func,
  prevStep: PropTypes.func
};

export default Review;
