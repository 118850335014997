import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== MFA SETUP CARD ============================== */
const MFASetupCard = ({
  icon,
  title,
  description,
  buttonName,
  isButtonPrimary = true,
  onButtonClick
}) => {
  return (
    <Card className="settings-mfa-card">
      <div className="mfa-icon-text">
        <span className="cs-neutral-80">
          <CapsyncIcon title={icon} size="38" />
        </span>
        <div className="mfa-content-text">
          <h4 className="cs-regular-h4 cs-neutral-100">{title}</h4>
          <h6 className="cs-regular-h6 cs-neutral-60">{description}</h6>
        </div>
      </div>
      <Button
        className={`${isButtonPrimary ? 'cs-btn-primary' : 'cs-btn-secondary'} lg-btn cs-regular-h5`}
        onClick={onButtonClick}>
        {buttonName}
      </Button>
    </Card>
  );
};

// PROPS TYPE
MFASetupCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  isButtonPrimary: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

export default MFASetupCard;
