import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENT IMPORTS
import GoogleQRCode from '../../../components/GoogleQRCode';
import { MFA_TYPES } from '../../../constants/system';

// API
import { generateMFAOTP, googleMFASetup } from '../../../slices/authSlice';

/* ============================== GOOGLE QR MODAL ============================== */
const GoogleQRModal = ({ show, setShow, setShowGooglePinModal }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      dispatch(
        generateMFAOTP({
          mfaType: MFA_TYPES.AUTHENTICATOR,
          payload: { user_id: user && user.id }
        })
      );
    }
  }, [show]);

  const handleContinue = async () => {
    await dispatch(googleMFASetup({ user_id: user && user.id }));
    setShow(false);
    setShowGooglePinModal(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      dialogClassName="cs-md-modal cs-modal-multi-button">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">
          Authenticator app verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-code-modal">
          <h5 className="cs-regular-h5 cs-neutral-80">
            Please use Google Authenticator to scan the barcode below.
          </h5>
          <GoogleQRCode width={193} height={193} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="cs-btn-primary md-btn cs-regular-h5" onClick={handleContinue}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
GoogleQRModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  setShowGooglePinModal: PropTypes.func.isRequired
};

export default GoogleQRModal;
