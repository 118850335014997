import React from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';

/* ============================== PAGE NOT FOUND ============================== */
const PageNotFound = () => {
  return (
    <Container>
      <div className="page404">
        <Row>
          <Col lg={12}>
            <div className="error-container">
              {/* <LogoImage /> */}
              <div className="error-image">
                <Image src={require('../assets/images/404-image.png')} className="pagenotfound" />
              </div>
              <div className="error-content">
                <h1 className="cs-semi-bold-h1 cs-neutral-100">Page Not Found</h1>
                <p className="cs-medium-sub-heading-l  cs-neutral-80">
                  We're sorry, the page you requested could not be found please go back to the
                  homepage
                </p>
                <span className="back-to-sign-in">
                  <button className="cs-btn-tertiary lg-btn cs-regular-h5 btn" type="button">
                    Back to sign in
                  </button>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PageNotFound;
