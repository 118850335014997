import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import CustomTable from '../../../components/CustomTable';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== VIEW COMPANIES MODAL ============================== */
const ViewCompaniesModal = ({ companies, handleCancel }) => {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      label: 'Company Name',
      renderCell: (item) => item.company_name
    },
    {
      label: '',
      renderCell: (item) => (
        <div
          className="text-end  cs-neutral-80"
          onClick={() =>
            navigate('/dashboard/company', {
              state: {
                companyId: item.id
              }
            })
          }>
          <span className="cursor-pointer">
            <CapsyncIcon title="chevron-right-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];

  return (
    <Modal
      title="Check assign"
      className="cs-md-modal cs-assign-modal"
      body={
        <div className="choose-user-modal">
          <span className="cs-regular-body-text-m cs-neutral-80">List of company</span>
          <CustomTable columns={COLUMNS} data={companies} isPaginate={false} />
        </div>
      }
      show={true}
      isCloseButton={false}
      cancelButtonLabel={'Okay'}
      handleOnCancel={handleCancel}
    />
  );
};

// PROPS TYPE
ViewCompaniesModal.propTypes = {
  companies: PropTypes.array,
  handleCancel: PropTypes.func
};

export default ViewCompaniesModal;
