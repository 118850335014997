const AUTH_CONTENT = {
  BUSINESS_TITLE_ONE: 'Tell us about your business',
  BUSINESS_TITLE_TWO: 'Tell us more about your business',
  BUSINESS_TITLE_THREE: "Let's verify your business",
  BUSINESS_TITLE_FOUR: 'Tell us about your industry',
  BUSINESS_TITLE_REVIEW: 'Review Information',
  BUSINESS_DETAIL_ONE:
    'We need to gather additional information about your company in order to validate and safeguard your account. Please ensure that the details you provide match those of your company registration.',
  BUSINESS_DETAIL_TWO:
    'Every business is unique and we want to capture the intricacies of yours. By understanding your company better, we can tailor our services to meet your specific digital finance needs.',
  BUSINESS_DETAIL_THREE:
    'We need to gather additional information about your company in order to validate and safeguard your account. Please ensure that the details you provide match those of your company registration.',
  BUSINESS_DETAIL_FOUR:
    'Every business is unique, and we want to capture the intricacies of yours. By understanding your company better, we can tailor our services to meet your specific digital finance needs more effectively.',
  BUSINESS_DETAIL_REVIEW:
    'Review your information before submitting, if you have any changes in the given information you can update it from here',
  TOKEN_EXPIRED_TITLE: 'Token Expired',
  TOKEN_EXPIRED_DETAIL: 'Your Token has been expired, Please enter your email for re-verification!',
  BUSINESS_INFO_CONFIRMED_TITLE: 'Confirmed Information',
  BUSINESS_INFO_CONFIRMED_DETAIL:
    'You already been submitted your information. A member of our team will reach out to you soon.'
};

export { AUTH_CONTENT };
