import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container, Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';

// CSS IMPORTS
import '../../assets/css/settings.css';

// COMPONENT IMPORTS
import MFASetup from './tabs/MFASetup';
import AuditLogs from './tabs/AuditLogs';
import AccountDetails from './tabs/AccountDetails';
import CustomBranding from './tabs/CustomBranding';
import BillingHistory from './tabs/BillingHistory';
import Breadcrumb from '../../components/Breadcrumb';
import CapsyncIcon from '../../components/CapsyncIcon';
import TeamCollaboration from './tabs/TeamCollaboration';

// API
import { useUser } from '../../slices/authSlice';

const BreadcrumbsDetails = [
  {
    label: 'Dashboard',
    link: '/dashboard'
  },
  {
    label: 'Settings'
  }
];

/* ============================== SETTING PAGE ============================== */
const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useUser();

  const pathArray = location.pathname.split('/');
  const activeTabPath = pathArray[pathArray.length - 1];

  const TAB_LIST_ITEMS = [
    {
      label: 'Account Details',
      isShow: true,
      element: <AccountDetails />,
      path: 'account-details'
    },
    { label: 'MFA Setup', isShow: true, element: <MFASetup />, path: 'mfa-setup' },
    {
      label: 'Billing History',
      isShow: user?.role_id === 5 ? true : false,
      element: <BillingHistory />,
      path: 'billing-history'
    },
    {
      label: 'Custom Branding',
      isShow: user?.role_id !== 5 ? false : true,
      element: <CustomBranding />,
      path: 'custom-branding'
    },
    {
      label: 'Team Collaboration',
      isShow: user?.role_id !== 2 ? true : false,
      element: <TeamCollaboration />,
      path: 'team-collaboration'
    },
    { label: 'Audit Logs', isShow: true, element: <AuditLogs />, path: 'audit-logs' }
  ];

  const navigateTab = (tab) => {
    if (activeTabPath === tab.path) return;
    const currBasePath = pathArray.slice(1, -1);
    navigate(`/${currBasePath.join('/')}/${tab.path}`);
  };

  const tabContentRender = () => {
    const tab = TAB_LIST_ITEMS.find((tab) => tab.path === activeTabPath);
    if (tab) return tab.element;

    return null;
  };

  const [loading] = useOutletContext();
  return (
    <section className="settings-main-section">
      <div className="container-fluid">
        {loading ? (
          <div className="cs-section-topbar">
            <div className="skeleton-breadcrumb">
              <div className="skeleton-breadcrumb-1">
                {' '}
                <Skeleton width={50} />
              </div>
              <div className="skeleton-breadcrumb-2">
                {' '}
                <Skeleton width={20} />
              </div>
              <div className="skeleton-breadcrumb-3">
                <Skeleton width={50} />
              </div>
            </div>
            <div className="cs-title">
              <h1 className="default-semi-bold-h1 cs-neutral-100">
                <Skeleton width={200} />
              </h1>
            </div>
          </div>
        ) : (
          <Breadcrumb data={BreadcrumbsDetails} title="Settings" />
        )}

        {loading ? (
          <div className="setting-mobile-dropdown for-mob">
            <Skeleton width="100" height={30} />
          </div>
        ) : (
          <div className="settings-tab-dropdown for-flex-mob">
            <Dropdown className="cs-form-dropdown">
              <Dropdown.Toggle>
                {TAB_LIST_ITEMS.find((item) => item.path === activeTabPath)?.label}
                <span className="cs-neutral-100">
                  <CapsyncIcon title="chevron-down-outlined" size="14" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {TAB_LIST_ITEMS.map(
                  (tab) =>
                    tab.isShow && (
                      <Dropdown.Item
                        key={tab.label}
                        onClick={() => navigateTab(tab)}
                        active={activeTabPath === tab.path}
                        className={activeTabPath === tab.path ? 'cs-selected-item' : ''}>
                        {tab.label}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <div className="card">
          <div className="settings-body">
            {loading ? (
              <div className="for-des">
                <div className="skeleton-account-tabs">
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={80} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={150} />
                  </p>
                  <p className="skeleton-account-tab">
                    {' '}
                    <Skeleton width={100} />
                  </p>
                </div>
              </div>
            ) : (
              <div className="settings-tab-btn cs-tabs for-flex-des">
                {TAB_LIST_ITEMS.map((tab) => {
                  if (!tab.isShow) {
                    return null;
                  }
                  return (
                    <button
                      type="button"
                      key={tab.label}
                      onClick={() => navigateTab(tab)}
                      className={`cs-stroke-bottom-tab cs-regular-h5 btn btn-primary ${activeTabPath === tab.path ? 'cs-stroke-bottom-active' : ''}`}>
                      {tab.label}
                    </button>
                  );
                })}
              </div>
            )}
            <Container fluid>
              {/* <TabContentRender /> */}
              {tabContentRender()}
            </Container>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
