import { createSlice } from '@reduxjs/toolkit';

// INITIAL STATE
const initialState = {
  mfa: {}
};

/* ============================== MFA SLICE ============================== */
const mfaSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    setMFA: (state, action) => {
      state.mfa = { ...state.mfa, ...action.payload };
    },
    clearMFA: (state) => {
      state.mfa = {};
    }
  }
});

export const { setMFA, clearMFA } = mfaSlice.actions;
export default mfaSlice.reducer;
