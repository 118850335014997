import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// COMPONENT IMPORTS
import CapsyncIcon from './CapsyncIcon';

/* ============================== BREADCRUMB ============================== */
const Breadcrumb = ({ data = [], title = '' }) => {
  return (
    <div className="cs-section-top-bar">
      <div className="cs-breadcrumb">
        {data.map((val, i) => {
          if (data.length === i + 1) {
            return (
              <span key={i} className="cs-regular-sub-heading-s cs-neutral-100">
                {val.label}
              </span>
            );
          }
          return (
            <React.Fragment key={i}>
              <Link to={'/dashboard'} className="cs-regular-sub-heading-s cs-neutral-80">
                {val.label}
              </Link>
              <span className="cs-icon cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="12" />
              </span>
            </React.Fragment>
          );
        })}
      </div>
      <div className="cs-dashboard-title">
        <h1 className="cs-semi-bold-h1 cs-neutral-100">{title}</h1>
      </div>
    </div>
  );
};

// PROPS TYPE
Breadcrumb.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string
};

export default Breadcrumb;
