import React from 'react';
import { useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import Branding from '../../Branding';
import SkeletonBranding from '../../components/skeleton/SkeletonBranding';

/* ============================== CUSTOM BRANDING ============================== */
const CustomBranding = () => {
  const [loading] = useOutletContext();
  return loading ? <SkeletonBranding /> : <Branding />;
};

export default CustomBranding;
