import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncToolTip from '../../../components/CapsyncToolTip';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== DELETE INDIVIDUAL MODAL ============================== */
const DeleteIndividualModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  handleRemoveIndividualAndCompany,
  handleRemoveIndividual
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenDeleteModal(false);
      }
    };
    if (openDeleteModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDeleteModal]);

  return (
    <Modal
      show={openDeleteModal}
      onHide={() => setOpenDeleteModal(false)}
      className="cs-delete-modal cs-delete-individual"
      dialogClassName="cs-md-modal"
      centered>
      <Modal.Header className="cs-md-modal">
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">Delete Individual</Modal.Title>
      </Modal.Header>
      <Modal.Body className="cs-text-center">
        <span className="delete-filled icon cs-icon cs-danger">
          <CapsyncIcon title="delete-validation" size="100" />
        </span>
        <div className="cs-modal-text cs-regular-body-text-m cs-neutral-90">
          Are you sure that you want remove this user from your Company?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <div className="cs-d-flex-btn">
            <Button onClick={handleRemoveIndividual} className="cs-btn-secondary cs-regular-h5">
              Remove from Company
            </Button>
            <span className="cs-neutral-80">
              <CapsyncToolTip
                Child={'info-outlined'}
                placement={'top'}
                message={
                  'Select this option if you want to remove the individual only from the selected company, but keep their account intact.'
                }
                type="icon"
                size="18"
                width="420px"
              />
            </span>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
DeleteIndividualModal.propTypes = {
  openDeleteModal: PropTypes.bool,
  setOpenDeleteModal: PropTypes.func,
  handleRemoveIndividualAndCompany: PropTypes.func,
  handleRemoveIndividual: PropTypes.func
};

export default DeleteIndividualModal;
