import React, { useEffect, useState } from 'react';
import { Button, Container, NavDropdown, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';

// COMPONENT IMPORTS
import { roles } from '../../utils/common';
import Searchbar from '../../components/Searchbar';
import CapsyncIcon from '../../components/CapsyncIcon';
import DeleteModal from '../../components/DeleteModal';
import CheckBox from '../../components/FormFields/CheckBox';
import EditCompanyModal from './components/EditCompanyModal';
import CreateCompanyModal from './components/CreateCompanyModal';
import CustomAccordionTable from '../../components/CustomAccordionTable';
import SkeletonIndCompany from '../components/skeleton/SkeletonIndCompany';
import ManageIndividualClientModel from './components/ManageIndividualClientModal';
import AlreadyExistingClientsModal from './components/AlreadyExistingClientsModal';

// HOOKS IMPORTS
import useDebounce from '../../hooks/useDebounce';

// API
import { getCompaniesList, resetError, deleteCompany } from '../../slices/clientSlice';
import { socket } from '../../config/Socket';

/* ============================== COMPANY CLIENT ============================== */
const CompanyClient = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { companiesRecord } = useSelector((state) => state.client);
  const role = user && user.user_role.role;

  const [isModelOpen, setIsModelOpen] = useState({
    addCompany: false,
    // viewDetails: false,
    manageClient: false,
    addClient: false,
    editCompany: false,
    removeUser: false
  });
  const [validationCompany, setValidationCompany] = useState(false);
  const [manageClientModelId, setManageClientModelId] = useState(-1);
  const [clientAdded, setClientAdded] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [activeRow, setActiveRow] = useState(-1);
  const [paginationData, setPaginationData] = useState({});
  const [columnDetails, setColumnDetails] = useState({});
  const [checkAccordion, setCheckAccordion] = useState({
    isOpen: false,
    accordionData: null
  });

  useEffect(() => {
    onPaginationValueChange({
      page: 1,
      limit: 10,
      isPaginate: true,
      search: search
    });
    setActiveRow(-1);
    return () => {
      setClientAdded(false);
    };
  }, [clientAdded]);

  const startSearch = useDebounce(() => {
    dispatch(getCompaniesList({ ...paginationData, search }));
  }, 1000);

  useEffect(() => {
    startSearch();
  }, [paginationData, search]);

  useEffect(() => {
    socket.on('sync_company_list', () => {
      onPaginationValueChange({
        page: 1,
        limit: 10
      });
    });
    return () => {
      socket.off('sync_company_list', () => {
        onPaginationValueChange({
          page: 1,
          limit: 10
        });
      });
    };
  }, []);

  const getAllCollaborationUsersList = (params) => {
    dispatch(getCompaniesList(params));
  };

  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false,
      search: search
    };
    getAllCollaborationUsersList(paginationLimits);
    setPaginationData(paginationLimits);
  };

  const onRemoveUserClick = () => {
    let clientArray;
    if (deleteUsers.length > 0) {
      clientArray = deleteUsers;
    } else {
      clientArray = selectedUsers.map(String);
    }
    dispatch(deleteCompany({ companies: clientArray })).then((res) => {
      if (res.payload.code === 200) {
        toast.success(res.payload.message);
        setSelectedUsers([]);
        setDeleteUsers([]);
        handleCloseModel('removeUser');
        setClientAdded(true);
      }
    });
  };

  const handleEditCompany = (id, e) => {
    e.stopPropagation();
    handleOpenModel('editCompany');
    const companyDetails =
      companiesRecord && companiesRecord.records.find((company) => company.id === id);
    setCompanyDetails(companyDetails);
  };

  const COLUMNS = [
    {
      label: (
        <CheckBox
          name="select-header"
          className={
            selectedUsers?.length > 1 && companiesRecord?.records.length !== selectedUsers?.length
              ? 'select-all'
              : ''
          }
          handleChange={() => handleSelectedUser('all')}
          values={{
            'select-header':
              companiesRecord &&
              companiesRecord.records &&
              companiesRecord.records.length === selectedUsers.length
          }}
        />
      ),
      renderCell: (item) => (
        <CheckBox
          name="select-header"
          handleChange={() => handleSelectedUser(item.id)}
          values={{ 'select-header': selectedUsers.indexOf(item.id) > -1 }}
          handleOnClick={(e) => e.stopPropagation()}
        />
      ),
      width: '30'
    },
    {
      label: 'Company name',
      renderCell: (item) => item.company_name
    },
    {
      label: 'Members',
      renderCell: (item) =>
        item?.company_clients?.length > 0 ? item?.company_clients?.length : item.member_count
    },
    {
      label: 'User Assigned',
      renderCell: (item) => (
        <span
          className="cursor-pointer cs-primary"
          onClick={(e) => {
            handleOpenModel('manageClient', e);
            setManageClientModelId(item.id);
          }}>
          {role === roles?.standard?.name ? 'View' : 'View & Manage'}
        </span>
      )
    },
    {
      label: 'Actions',
      renderCell: (item) => (
        <div className="cs-table-icons">
          <span
            className="cursor-pointer edit-outlined cs-edit-btn cs-neutral-80"
            onClick={(e) => handleEditCompany(item.id, e)}>
            <CapsyncIcon title="edit-outlined" size="14" />
          </span>
          <span
            onClick={(e) => {
              handleOpenModel('removeUser', e);
              setDeleteUsers([item.id.toString()]);
            }}
            className="cursor-pointer delete-outlined cs-delete-btn">
            <CapsyncIcon title="delete-outlined" size="14" />
          </span>
        </div>
      )
    },
    {
      label: '',
      renderCell: (item) => (
        <div className="text-end">
          <span className="cs-neutral-80 cursor-pointer">
            {checkAccordion?.isOpen && checkAccordion?.accordionData?.id === item.id ? (
              <CapsyncIcon title="chevron-up-outlined" size="18" />
            ) : (
              <CapsyncIcon title="chevron-down-outlined" size="18" />
            )}
          </span>
        </div>
      )
    }
  ];

  const handleOpenModel = (key, e) => {
    if (key === 'manageClient') {
      e.stopPropagation();
    }
    if (key === 'removeUser') {
      e.stopPropagation();
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
  };

  const handleAddMoreClient = () => {
    setIsModelOpen({
      ...isModelOpen,
      manageClient: false,
      addClient: true
    });
  };

  const handleCloseModel = (key) => {
    if (key === 'addCompany') {
      getAllCollaborationUsersList({
        page: companiesRecord.pagination.page,
        limit: companiesRecord.pagination.limit
      });
      dispatch(resetError());
    }
    if (key === 'editCompany') {
      getAllCollaborationUsersList({
        page: companiesRecord.pagination.page,
        limit: companiesRecord.pagination.limit
      });
      dispatch(resetError());
    }
    if (key === 'manageClient') setManageClientModelId(-1);
    if (key === 'removeUser') {
      getAllCollaborationUsersList({
        page: companiesRecord.pagination.page,
        limit: companiesRecord.pagination.limit
      });
      setSelectedUsers([]);
      setDeleteUsers([]);
    }
    if (key === 'addClient') {
      return setIsModelOpen({
        ...isModelOpen,
        manageClient: true,
        [key]: false
      });
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const handleSelectedUser = (key) => {
    if (key === 'all') {
      if (companiesRecord.records.length === selectedUsers.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(companiesRecord.records.map((client) => client.id));
      }
    } else {
      const index = selectedUsers.indexOf(key);
      if (index !== -1) {
        setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
      } else {
        setSelectedUsers([...selectedUsers, key]);
      }
    }
  };

  const handleTableSearch = (searchText) => {
    setSearch(searchText);
    if (columnDetails) {
      columnDetails.setRowStartIdx(0);
    }
    setPaginationData({
      page: 1,
      limit: columnDetails.rowsPerPage.value,
      isPaginate: columnDetails.rowsPerPage.value !== 'all' ? true : false
    });
  };

  const [loading] = useOutletContext();

  return (
    <section className="companies-main-section main-section">
      <Container fluid>
        {loading ? (
          <SkeletonIndCompany />
        ) : (
          <Row>
            <div className="cs-section-top-bar financial-advisor-top-bar">
              <div className="client-top-bar">
                <div className="cs-dashboard-title">
                  <h1 className="cs-semi-bold-h1 cs-neutral-100">Companies</h1>
                  <div className="company-add-drop">
                    <div className="cs-add-row for-flex-mob">
                      <Button
                        onClick={() => handleOpenModel('addCompany')}
                        // type="submit"
                        className="cs-btn-icon-primary lg-btn cs-regular-h5 add-btn ">
                        <span className="add-filled cs-neutral-10 icon cs-icon">
                          <CapsyncIcon title="add-filled" size="18" />
                        </span>
                        <span className="cs-nav-text">Create Company</span>
                      </Button>
                      {selectedUsers.length ? (
                        <div className="cs-table-user-action">
                          <NavDropdown
                            title={
                              <span className="cursor-pointer cs-primary">
                                <CapsyncIcon title="option-vertical-filled" size="18" />
                              </span>
                            }
                            className="cs-dropdown-nav">
                            <NavDropdown.Item
                              onClick={(e) => {
                                handleOpenModel('removeUser', e);
                              }}>
                              <CapsyncIcon title="delete-outlined" size="18" />
                              Delete
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      ) : null}
                    </div>
                    <span
                      className="cs-neutral-10 mobile-add-acc-icon"
                      onClick={() => handleOpenModel('addCompany')}>
                      <CapsyncIcon title="add-filled" size="18" />
                    </span>
                  </div>
                </div>
                <Searchbar
                  placeholder={'Search by company name'}
                  search={search}
                  setSearch={handleTableSearch}
                />

                <div className="cs-add-row for-flex-des">
                  <Button
                    onClick={() => handleOpenModel('addCompany')}
                    className="cs-btn-icon-primary lg-btn cs-regular-h5 add-btn">
                    <span className="add-filled cs-neutral-10 icon cs-icon">
                      <CapsyncIcon title="add-filled" size="18" />
                    </span>
                    <span className="cs-nav-text">Create Company</span>
                  </Button>
                  {selectedUsers.length ? (
                    <div className="cs-table-user-action">
                      <NavDropdown
                        title={
                          <span className="cursor-pointer cs-primary">
                            <CapsyncIcon title="option-vertical-filled" size="18" />
                          </span>
                        }
                        className="cs-dropdown-nav">
                        <NavDropdown.Item
                          onClick={(e) => {
                            handleOpenModel('removeUser', e);
                          }}>
                          <CapsyncIcon title="delete-outlined" size="18" />
                          Delete
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="companies-table">
              <CustomAccordionTable
                columns={COLUMNS}
                data={companiesRecord?.records?.length > 0 ? companiesRecord.records : []}
                isPaginate={true}
                totalRecords={
                  companiesRecord?.pagination?.itemCount
                    ? companiesRecord.pagination.itemCount
                    : companiesRecord?.records?.length
                }
                onPaginationValueChange={onPaginationValueChange}
                setClientAdded={setClientAdded}
                setColumnDetails={setColumnDetails}
                setCheckAccordion={setCheckAccordion}
                checkAccordion={checkAccordion}
                setActiveRow={setActiveRow}
                activeRow={activeRow}
              />
            </div>

            {validationCompany && (
              <AlreadyExistingClientsModal
                show={validationCompany}
                handleCloseModel={setValidationCompany}
              />
            )}
            {isModelOpen.addCompany && (
              <CreateCompanyModal
                handleCloseModel={() => handleCloseModel('addCompany')}
                handleOpenModel={() => setValidationCompany(true)}
                setClientAdded={setClientAdded}
              />
            )}
            {isModelOpen.editCompany && (
              <EditCompanyModal
                handleCloseModel={() => handleCloseModel('editCompany')}
                handleOpenModel={() => setValidationCompany(true)}
                setClientAdded={setClientAdded}
                companyDetails={companyDetails}
                setIsModelOpen={setIsModelOpen}
              />
            )}
            {isModelOpen.manageClient && (
              <ManageIndividualClientModel
                handleCloseModel={() => handleCloseModel('manageClient')}
                userId={manageClientModelId}
                handleAddMoreClient={handleAddMoreClient}
                fromTable={'companyClient'}
              />
            )}
            {isModelOpen.removeUser && (
              <DeleteModal
                show={isModelOpen.removeUser}
                onHandleClose={() => handleCloseModel('removeUser')}
                onHandleConfirm={onRemoveUserClick}
                deleteBodyText="You are going to delete this entry, Once it is deleted, you will be not able to recover this data"
                deleteButtonText="Delete"
              />
            )}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default CompanyClient;
