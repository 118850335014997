import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import { setMFA } from './mfaSlice';
import CONFIG from '../constants/config';
import { encrypt } from '../utils/system';
import { VALIDATION_MESSAGE } from '../constants/message';

// USERS SIGN-UP API CALL
export const userSignUp = createAsyncThunk('auth/userSignUp', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/signup`, data);
  return res;
});

// USERS SIGN-IN API CALL
export const userSignIn = createAsyncThunk('auth/userSignIn', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/signin`, data);
  return res;
});

// USER EMAIL VERIFICATION
export const emailVerification = createAsyncThunk('auth/verify_user', async (token) => {
  const res = await axios.get(`${CONFIG.BASE_URL}users/verify_user/${token}`);
  return res;
});

// RESEND EMAIL VERIFICATION
export const resendEmailVerification = createAsyncThunk(
  'auth/resendEmailVerification',
  async (data) => {
    const res = await axios.post(`${CONFIG.BASE_URL}users/resend_verification_email`, data);
    return res;
  }
);

// USER FORGOT PASSWORD
export const userForgotPassword = createAsyncThunk('auth/userForgotPassword', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/forgot_password/request`, data);
  return res;
});

// USER CHANGE PASSWORD
export const userChangePassword = createAsyncThunk('auth/userChangePassword', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/forgot_password`, data);
  return res;
});

// USER CHANGE AVATAR
export const userChangeAvatar = createAsyncThunk('auth/userChangeAvatar', async (data) => {
  const res = await axios.put(`${CONFIG.BASE_URL}users/set_avatar`, data);
  return res;
});

// USER CHANGE PROFILE IMAGE
export const userChangeProfileImage = createAsyncThunk(
  'auth/userChangeProfileImage',
  async (data) => {
    const res = await axios.put(`${CONFIG.BASE_URL}users/upload_profile`, data);
    return res;
  }
);

// CLEAR FORM AFTER SUBMIT
export const resetForm = createAction('auth/resetForm');

// LOG OUT USER AND CLEAR USER DETAILS
export const authUserLogout = createAction('auth/logout');

// SET MFA true
export const setMFATrue = createAction('auth/mfa-success');

// VERIFY FORM API CALLS
export const firmInquiry = createAsyncThunk('auth/firm_inquiry', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}firm/firm_inquiry`, data);
  return res;
});

export const getfirmInquiry = createAsyncThunk('auth/get_firm_inquiry', async (id) => {
  const res = await axios.get(`${CONFIG.BASE_URL}firm/get_firm_inquiry/${id}`);
  return res;
});

// MFA API CALLS
export const generateMFAOTP = createAsyncThunk('/users/users_mfa', async ({ mfaType, payload }) => {
  const res = await axios.post(`${CONFIG.BASE_URL}users/users_mfa/${mfaType}`, payload);
  return res;
});

// GET ACCESS UERR LIST
export const toAccessUserList = createAsyncThunk(
  'account-access/toAccessUserList',
  async (params) => {
    const res = await axios.get(`client/account/access-list`, { params });
    if (res.code === 200) {
      return res;
    }
  }
);

export const verifyMFAOTP = createAsyncThunk(
  '/users/mfa_otp_verify',
  async ({ mfaType, payload }) => {
    const res = await axios.post(`${CONFIG.BASE_URL}users/mfa_otp_verify/${mfaType}`, payload);
    return res;
  }
);

// GET USER DETAILS
export const getUserDetails = createAsyncThunk('/users/getuser', async (id) => {
  const res = await axios.get(`${CONFIG.BASE_URL}users/get_user_details_by_id/${id}`);
  return res;
});

// UPDATE USER DETAILS
export const updateUserDetails = createAsyncThunk('/users/updateuser', async (payload) => {
  const res = await axios.put(`${CONFIG.BASE_URL}users/update_user`, payload);
  return res;
});

export const googleMFASetup = createAsyncThunk(
  '/users/mfa_otp_verify/mfa_google_setup',
  async (payload) => {
    const res = await axios.put(`${CONFIG.BASE_URL}users/mfa_otp_verify/mfa_google_setup`, payload);
    return res;
  }
);

// File Encryption Change password API
export const fileChangePassword = createAsyncThunk(
  'file-encryption/changePassword',
  async (data, { rejectWithValue }) => {
    const res = await axios.put(`${CONFIG.BASE_URL}users/settings/file_encryption/pfs_file`, data);
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// File Encryption Other file Change password API
export const otherFileChangePassword = createAsyncThunk(
  'file-encryption/changeOtherPassword',
  async (data, { rejectWithValue }) => {
    const res = await axios.put(
      `${CONFIG.BASE_URL}users/settings/file_encryption/other_pfs_file`,
      data
    );
    if (res.code === 200) {
      return res;
    } else {
      return rejectWithValue(res.message || '');
    }
  }
);

// ADD USERS PROFILE
export const addUsersProfile = createAsyncThunk('auth/addUsersProfile', async (data) => {
  const res = await axios.put(`${CONFIG.BASE_URL}users/profile_set/${data.id}`, data.details);
  return res;
});

// Reset MFA
export const resetMFA = createAsyncThunk(
  '/users/mfa_otp_verify/mfa_google_setup',
  async ({ mfaType, payload }) => {
    const res = await axios.put(`${CONFIG.BASE_URL}users/reset_mfa/${mfaType}`, payload);
    return res;
  }
);

// INITIAL STATE
const initialState = {
  user: {},
  businessFormData: { step1: {}, step2: {}, step3: {}, step4: {} },
  status: null,
  message: '',
  token: null,
  toAccessUser: null,
  isAuthenticated: false,
  savedAvatar: null,
  userVerifiedDetails: null,
  verfiedToken: null
};

/* ============================== AUTH SLICE ============================== */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSavedAvatar: (state, action) => {
      state.savedAvatar = action.payload;
    },
    setRemoveMessage: (state) => {
      state.message = '';
      state.status = null;
    },
    setVerfiedToken: (state, action) => {
      state.verfiedToken = action.payload;
    }
  },
  extraReducers: (builder) => {
    // SIGN UP API CASES
    builder
      .addCase(userSignUp.fulfilled, (state, { payload }) => {
        const { status, data, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        state.user = data;
      })
      .addCase(userSignUp.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(userSignIn.fulfilled, (state, { payload }) => {
        const { data: { loginUserData = {}, token } = {}, status, message } = payload;
        state.isAuthenticated = true; // User Athenticated flag
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        localStorage.setItem('id', encrypt(loginUserData?.id?.toString()));
        if (status) {
          state.user = loginUserData;
          state.token = token;
          state.savedAvatar =
            loginUserData &&
            loginUserData.user_personal_details &&
            loginUserData.user_personal_details.profile_image_url;
        }
        // Setting user info when sign in
        secureLocalStorage.setItem('token', token);
      })
      .addCase(userSignIn.rejected, (state, { payload }) => {
        const { status } = payload;
        state.status = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(firmInquiry.fulfilled, (state, { payload }) => {
        const { status, message, data } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        if (status) {
          state.businessFormData = {
            ...state.businessFormData,
            ['step' + data.inquiry_step]: data
          };
        }
      })
      .addCase(firmInquiry.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(toAccessUserList.fulfilled, (state, action) => {
        state.toAccessUser = action?.payload?.data;
      })
      .addCase(getfirmInquiry.fulfilled, (state, { payload }) => {
        const { status, message, data } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        if (status) {
          state.businessFormData = data;
        }
      })
      .addCase(getfirmInquiry.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(resendEmailVerification.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
      })
      .addCase(resendEmailVerification.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(emailVerification.fulfilled, (state, { payload }) => {
        const { status, message, data } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
        state.user = {
          ...state.user,
          isEmailVerified:
            message.includes('already verified') || message.includes('verify successfully')
              ? true
              : false
        };
        data && (state.user = { ...state.user, id: data.user_id });
        state.userVerifiedDetails = payload?.data;
        if (state.userVerifiedDetails) {
          state.message = '';
        }
      })
      .addCase(emailVerification.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      // .addCase(userForgotPassword.fulfilled, (state, { payload }) => {
      //   const { status, message } = payload;
      //   state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
      //   state.message = message;
      // })
      // .addCase(userForgotPassword.rejected, (state) => {
      //   state.status = CONFIG.STATUS.ERROR;
      // })
      .addCase(userChangePassword.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
      })
      .addCase(userChangePassword.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      })
      .addCase(resetForm, (state) => ({ ...state, message: '', status: null }))
      .addCase(userChangeAvatar.fulfilled, (state, { payload }) => {
        state.user.savedAvatar =
          state &&
          state.user_personal_details &&
          state.user_personal_details.data.profile_image_url;
      })
      .addCase(setMFA, (state, action) => {
        state.mfa = action.payload;
      })
      .addCase(verifyMFAOTP.fulfilled, (state, action) => {
        state.mfa = { ...state.mfa, ...action.payload };
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action?.payload?.data;
      })
      .addCase(verifyMFAOTP.rejected, (state, action) => {
        state.mfa.code = 500;
        state.mfa.validation = VALIDATION_MESSAGE.INTERNAL_SERVER_ERROR;
      })
      .addCase(authUserLogout, (state) => ({ ...initialState }))
      .addCase(generateMFAOTP.fulfilled, (state, action) => {
        state.status = CONFIG.STATUS.SUCCESS;
        const payload = action.payload;
        const otpAuthUrl =
          payload.data &&
          payload.data.res_data &&
          payload.data.res_data.otpauth_url &&
          payload.data.res_data.otpauth_url.toString();
        state.mfa = { ...state.mfa, otpauth_url: otpAuthUrl };
      })
      .addCase(setMFATrue, (state) => ({ ...state, mfa: { verified: true } }))
      .addCase(generateMFAOTP.pending, (state, action) => {
        state.status = CONFIG.STATUS.PROGRESS;
      })
      .addCase(fileChangePassword.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status = CONFIG.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(fileChangePassword.rejected, (state, { payload }) => {
        state.status = CONFIG.STATUS.ERROR;
        state.message = payload;
      })

      .addCase(otherFileChangePassword.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status = CONFIG.STATUS.SUCCESS;
        state.message = message;
      })
      .addCase(otherFileChangePassword.rejected, (state, action) => {
        state.status = CONFIG.STATUS.ERROR;
        state.message = action.payload;
      })
      .addCase(addUsersProfile.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        state.status = status ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.ERROR;
        state.message = message;
      })
      .addCase(addUsersProfile.rejected, (state) => {
        state.status = CONFIG.STATUS.ERROR;
      });
  }
});

const { reducer } = authSlice;
export const { setSavedAvatar, setRemoveMessage, setVerfiedToken } = authSlice.actions;
export default reducer;

export const selectUser = (state) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};

export const selectUserid = (state) => state.auth.user?.id;
export const useUserID = () => {
  const userId = useSelector(selectUserid);
  return useMemo(() => userId, [userId]);
};
export const selectServerMessage = (state) => state.auth.message;
export const useServerMessage = () => {
  const message = useSelector(selectServerMessage);
  return useMemo(() => message, [message]);
};
export const selectStatus = (state) => state.auth.status;
export const useApiStatus = () => {
  const status = useSelector(selectStatus);
  return useMemo(() => status, [status]);
};

export const selectToAccessUser = (state) => state.auth.toAccessUser;
export const useToAccessUser = () => {
  const toAccessUser = useSelector(selectToAccessUser);
  return useMemo(() => toAccessUser, [toAccessUser]);
};
